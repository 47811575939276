import React, {Component} from 'react';
import EventTeamItem from './EventTeamItem';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import Slide from 'react-reveal/Slide'

class EventTeam extends Component{
    render(){
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 2000,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  }
                }
              ],
        };
        return(
            <section className="event_team_area sec_pad">
            <div className="container">
                <div className="hosting_title security_title text-center">
                    <h2 className="wow fadeInUp" data-wow-delay="0.2s">Team YPay</h2>
                </div>
                <Slide left>

                <Slider ref={c => (this.slider = c)} className="event_team_slider" {...settings}>
                    
                <EventTeamItem instagram="" linkedin="https://www.linkedin.com/in/iqra-muhammad-392a03184" image="team13.png" name="Iqra Muhammad" post="Customer Experience Associate"/>

                    <EventTeamItem instagram="" image="team1.png" name="Muhammad Anas Tahir" linkedin="https://www.linkedin.com/in/manastahir/"  post="Senior Operations Associate"/>

                <EventTeamItem instagram="" image="team9.jpg" name="Syed Hamza Hoda" linkedin="https://www.linkedin.com/in/syed-hamza-hoda-83ab3a207/"  post="Product Engineer"/>
                
                    <EventTeamItem instagram="" linkedin="http://www.linkedin.com/in/saad97" image="team2.jpg" name="Saad Ali" post="Strategy & Operations Manager"/>
                    

                    <EventTeamItem instagram="" linkedin="https://www.linkedin.com/in/syed-ali-arshad-b10b69141" image="team7.jpg" name="Syed Ali Arshad" post="Associate Product Manager"/>

                    <EventTeamItem instagram="" linkedin="https://www.linkedin.com/in/zhoyia-asad" image="team16.png" name="Zhoyia Asad" post="Head of Marketing"/>
                    
                    <EventTeamItem instagram="" image="team12.png" name="Ali Shahzad" linkedin="https://www.linkedin.com/in/ali-sahzad-066a601a5/"  post="Marketing Associate"/>

                    <EventTeamItem instagram="" linkedin="https://www.linkedin.com/in/taher-qutub-b1b953242" image="team11.png" name="Taher Qutbuddin" post="Operations Associate"/>
                    
                    <EventTeamItem image="team14.png" name="Samad Qureshi" linkedin="https://www.linkedin.com/in/abdul-samad-9b0b31172" instagram="" post="Software Engineer - I"/>
                    
                    
                    <EventTeamItem instagram="" linkedin="https://www.linkedin.com/in/shoaib-hassan-askari-2b7689157/" image="team17.png" name="Shoaib Hassan Askari" post="Software Quality Assurance Engineer"/>
                    
                    
                </Slider>
                </Slide>
            </div>

            <div style={{ display:"flex",justifyContent:"center",marginTop:"20px" }}>
            <Link style={{ textDecoration:"none!important",color:"#04638b" }} to="/team"> <span>Meet the Team <i className="arrow_right" ></i></span> </Link>
            </div>
        </section>
        )
    }
}

export default EventTeam;