import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class FooterThree extends Component{
    render(){
        let FooterData = this.props.FooterData;
        return(
            <footer className="footer_nine_area">
                <div className="footer_nine_top">
                    <div className="footer_shap left"></div>
                    <div className="container">
                        <div className="row">

                        <a href="index.html" className="f-logo footer3-YPay-logo-mobile"><img src={require ('../../img/1.png')} alt=""/></a>

                        <div className="">
                                <div className="f_widget company_widget pr_100">




                                <div className='footer3-mainheading-container'>

                                        <div >
                                            <h3>Stay Connected With Us</h3>
                                            <h3>for New Updates</h3>
                                        </div>
                                        <div  >
                                            <h3>Powered By</h3>
                                            <h3>YPay Team</h3>
                                        </div>


                                        </div>
                          

                            </div>

                          


      

                            <div class="footer3-socialicons">
                          
                            <ul>
                            <li>
                                <a target="__blank" href="https://www.facebook.com/ypaypk">
                                <i class="fab fa-facebook-f icon"></i>    </a>
                            </li>
                            <li>
                                <a target="__blank" href="https://twitter.com/ypayfinancialpk"><i class="fab fa-twitter icon"></i></a>
                            </li>
                            <li>
                                <a target="__blank" href="https://www.linkedin.com/company/ypayfinancial/"><i class="fab fa-linkedin-in icon"></i></a></li>
                            {/* <li> */}
                                {/* <a href="#"><i class="fab fa-google-plus-g icon"></i></a></li> */}
                            </ul>

                            </div>





                        <div className='footer3-page-links-maincontainer' >

                      

                        <div className='footer3-page-links-container1'>
                            
                        
                                                        <p className='footer3-page-links-text' style={{ 
                                                         
                                                         }} ><a href="/privacypolicy" target={"__blank"} className="f_400" style={{ color:"#fff" }}>PRIVACY</a></p>
                                                        <p className='footer3-page-links-text'><a target={"__blank"} href="/faqs" className="f_400" style={{ color:"#fff" }}>FAQs</a></p>

<p className='footer3-page-links-text'><a href="/blogs" target={"__blank"} className="f_400" style={{ color:"#fff" }}>BLOGS</a></p>

                                                        </div>

                                                        <div className='footer3-page-links-container2' >

<a href="index.html" className="f-logo footer3-YPay-logo-desktop"><img src={require ('../../img/1.png')} alt=""/></a>
</div>



                        </div>



                            <div className='footer3-disclaimer-container' style={{ }}>


                                <div className='col-lg-12 col-md-12'>
                                <p className="footer3-disclaimer-text mt_40 f_600" style={{ }}>Disclaimer: • YPay is an investing platform where users can find a variety of mutual funds to invest in and invest money without any hassles. • YPay provides details and does not advise or recommend a mutual fund. • Investors shall invest at their discretion. • YPay does not guarantee any return and security of the capital invested through our platform. 
                                </p>

                                <p className='footer3-disclaimer-text mt_40 f_600'>
                                For any complaints/grievances regarding investments, please contact the designated grievance officer Mr. Usama Abid.
                                Email: usama@ypayfinancial.com</p>
                                </div>
                                {/* <div className='col-lg-6 col-md-12'>
                                <p className="footer3-disclaimer-text mt_40 f_600" style={{ 
                                 
                                    }}>YPay Financials is a literacy Platform and is aimed to make investment platforms accessible to every individuals. Ypay has no charge over individual’s Money as mentioned in our Terms and Conditions
                                    YPay Financials Is an financial literacy platform registered by SECP (Lisence Number) (if regulated by state bank “to be confirmed”) that is security and exchange commission of Pakistan.
                                    </p>
                                </div> */}


                            </div>

                                  

                                   






                        </div>
                

                        


                          
                            {/* <div className="col-lg-4 col-md-6">
                                <div className="f_widget about-widget">
                                    <h3 className="f-title f_600 f_size_16 mb-30">About Us</h3>
                                    <ul className="list-unstyled f_list">
                                        {
                                            FooterData.aboutLinks.map(links => {
                                                return(
                                                    <li key={links.id}><a href={links.url} target="_blank">{links.item}</a></li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div> */}
                            {/* <div className="col-lg-4 col-md-6">
                                <div className="f_widget about-widget">
                                    <h3 className="f-title f_600 f_size_16 mb-30">More Links</h3>
                                    <ul className="list-unstyled f_list">
                                        {
                                            FooterData.helpLinks.map(links => {
                                                return(
                                                    <li key={links.id}><Link to={links.url} target="_blank">{links.item}</Link></li>
                                                )
                                            })
                                        } */}
                                        {/* <li ><a href="https://calendly.com/usama-ypay-financial" target="_blank">Mashwara</a></li> */}

                                    {/* </ul> */}
                                {/* </div> */}
                            {/* </div> */}


                            {/* <div className="col-lg-4 col-md-6 pl_100"> */}
                                {/* <div className="f_widget about-widget"> */}
                                    {/* <h3 className="f-title f_600 f_size_16 mb-30">Office Address</h3> */}
                                    {/* <ul className="list-unstyled f_list" style={{ color:"#f2effd" }}> */}
                                       
                                                    {/* <li>National Incubation Center,  */}
                                                    {/* NED University, University Road,  */}
                                                    {/* Karachi, Sindh, Pakistan 75270.</li> */}
                                       
                                    {/* </ul> */}
                                {/* </div> */}

                                {/* <div className="f_widget company_widget wow fadeInLeft" data-wow-delay="0.2s"> */}
                                                    {/* <div className="widget-wrap"> */}
                                                        {/* <p  className="f_600 f_p f_size_15 mb-0 l_height34"><span style={{padding:"0px",color:"#f2effd"}}>Email:</span> <a href="mailto:hello@ypayfinancial.com" className="f_400" style={{ color:"#fff" }}>hello@ypayfinancial.com</a></p> */}
                                                        {/* <p  className="f_600 f_p f_size_15 mb-0 l_height34"><span className='' style={{padding:"0px",color:"#f2effd"}}>Phone:</span> <a href="tel:923152008961" className="f_400" style={{ color:"#fff" }}>+92-315-200-8961</a></p> */}
                                                    {/* </div> */}
                                                    {/* <form action="#" className="f_subscribe mailchimp" method="post">
                                                        <input type="text" name="EMAIL" className="form-control memail" placeholder="Email"/>
                                                        <button className="btn btn-submit" type="submit"><i className="ti-arrow-right"></i></button>
                                                    </form> */}
                                                {/* </div> */}


                            {/* </div> */}

                            <div className="">
                                <div className="f_widget company_widget pr_100">


                                    <a href="https://sdms.secp.gov.pk/~sdmsadmn/" target="_blank"><img style={{marginTop:"20px"}} src={require ('../../img/footer.jpg')} alt=""/></a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="footer_nine_bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-7 align-self-center">
                                <p className="mb-0 f_400 text-white">Copyright © 2023 All rights reserved | Powered by <a href=".#">YPay Tech Team</a></p>
                            </div>
                            {/* <div className="col-sm-5">
                                <select className="selectpicker flag_selector" data-width="fit">
                                    <option data-content='<span className="flag-icon flag-icon-us"></span> English'>English</option>
                                    <option data-content='<span className="flag-icon flag-icon-mx"></span> Español'>Español</option>
                                    <option data-content='<span className="flag-icon flag-icon-us"></span> English'>Potogal</option>
                                    <option data-content='<span className="flag-icon flag-icon-mx"></span> Español'>Brazil</option>
                                </select>
                            </div> */}
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
export default FooterThree;