import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import YPAY101Slider from "../components/YPAY101Slider/YPAY101Slider.js"

const YPAY101 = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="YPAY 101" Pdescription="Home" Cdescription="> YPAY101"/>

            <YPAY101Slider />
            <YPAY101Slider />
            <YPAY101Slider />

            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default YPAY101;