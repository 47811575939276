import React, {Component} from 'react';
import EventTeamItem from './EventTeamItem';
import Slider from 'react-slick';

class Team extends Component{
    render(){
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            arrows: false,
            autoplay: false,
            autoplaySpeed: 2000,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  }
                }
              ],
        };
        return(
            <section className="event_team_area sec_pad">
            <div className="container">
                <div className="hosting_title security_title text-center">
                    <h2 className="wow fadeInUp" data-wow-delay="0.2s">Team YPay</h2>
                </div>
                <Slider ref={c => (this.slider = c)} className="event_team_slider" {...settings}>
                    <EventTeamItem instagram="" linkedin="https://www.linkedin.com/in/sarfrazhussayn/" image="founder1.jpg" name="Sarfraz Hussain" post="Chief Executive Officer"/>
                    <EventTeamItem instagram="" image="founder2.jpg" name="Muhammad Furqan Karim" linkedin="https://www.linkedin.com/in/furqankarimkidwai/" post="Chief Operating Officer"/>

                  <EventTeamItem instagram="" linkedin="http://www.linkedin.com/in/saad97" image="team2.jpg" name="Saad Ali" post="Strategy & Operations Manager"/>

                </Slider>

                <Slider ref={c => (this.slider = c)} className="event_team_slider" {...settings}>
                
                {/* <EventTeamItem image="team5.jpg" name="Humama Ali" linkedin="https://www.linkedin.com/in/humama-ali-0095" instagram="" 
                post="Business Development & Strategy"/> */}
                
                <EventTeamItem instagram="" linkedin="https://www.linkedin.com/in/iqra-muhammad-392a03184" image="team13.png" name="Iqra Muhammad" post="Customer Experience Associate"/>


                <EventTeamItem instagram="" image="team9.jpg" name="Syed Hamza Hoda" linkedin="https://www.linkedin.com/in/syed-hamza-hoda-83ab3a207/"  post="Product Engineer"/>

                {/* <EventTeamItem instagram="" linkedin="https://www.linkedin.com/in/angela-shah-887103114/" image="team6.jpg" name="Angela Shah" post="Growth & Strategy Lead"/> */}

                <EventTeamItem instagram="" linkedin="https://www.linkedin.com/in/zhoyia-asad" image="team16.png" name="Zhoyia Asad" post="Head of Marketing"/>


                </Slider>


                <Slider ref={c => (this.slider = c)} className="event_team_slider" {...settings}>
                <EventTeamItem instagram="" linkedin="https://www.linkedin.com/in/shoaib-hassan-askari-2b7689157/" image="team17.png" name="Shoaib Hassan Askari" post="Software Quality Assurance Engineer"/>

                {/* <EventTeamItem instagram="" image="team10.jpg" name="Ariba Owais" linkedin="https://www.linkedin.com/in/ariba-owais-112675217/"  post="Junior Marketing Associate"/> */}

                <EventTeamItem instagram="" image="team12.png" name="Ali Shahzad" linkedin="https://www.linkedin.com/in/ali-sahzad-066a601a5/"  post="Marketing Associate"/>

                <EventTeamItem instagram="" image="team1.png" name="Muhammad Anas Tahir" linkedin="https://www.linkedin.com/in/manastahir/"  post="Senior Operations Associate"/>
                </Slider>

                <Slider ref={c => (this.slider = c)} className="event_team_slider" {...settings}>
                
                {/* <EventTeamItem instagram="" linkedin="https://www.linkedin.com/in/umer-anwr/" image="team3.jpg" name="Umer Anwar" post="Experience Analyst"/> */}

                <EventTeamItem instagram="" linkedin="https://www.linkedin.com/in/taher-qutub-b1b953242" image="team11.png" name="Taher Qutbuddin" post="Operations Associate"/>

                <EventTeamItem image="team14.png" name="Samad Qureshi" linkedin="https://www.linkedin.com/in/abdul-samad-9b0b31172" instagram="" post="Software Engineer - I"/>


                <EventTeamItem instagram="" linkedin="https://www.linkedin.com/in/syed-ali-arshad-b10b69141" image="team7.jpg" name="Syed Ali Arshad" post="Associate Product Manager"/>

  {/* <EventTeamItem instagram="" linkedin="https://www.linkedin.com/in/samra-j-a89384253" image="team4.jpg" name="Samra Jamil" post="Marketing & Creatives Lead"/>                    <EventTeamItem image="team2.jpg" name="John Deo" post="Wp Developer"/> */}
                </Slider>
            </div>
        </section>
        )
    }
}

export default Team;


// import React from 'react';
// import Sectitle from '../Title/Sectitle';
// import Teamitem from '../Team/Teamitem';
// const Team =()=>{
//     return(



//         <section className="experts_team_area sec_pad">
//             <div className="container">
//                 <Sectitle sClass="sec_title text-center mb_70" Title="Not your ordinary team" tClass="t_color3" TitleP="Meet the experts that are on a mission to revolutionize the digital investments landscape in Pakistan. Combined we have years of professional experience and industry knowledge being contributed to build YPay, the autopilot for millennial investors."/>
//                 <div className="row">
//                     <div className="col-lg-3 col-sm-6">
//                         <Teamitem linkedinUrl="https://www.linkedin.com/in/sarfrazhussayn/" teamImage="1.png" memberN="Sarfraz Hussain" memberd="Co-Founder & Chief Executive Officer"/>
//                     </div>
//                     <div className="col-lg-3 col-sm-6">
//                         <Teamitem linkedinUrl="https://www.linkedin.com/in/furqankarimkidwai/" teamImage="2.png" memberN="Muhammad Furqan Karim" memberd="Co-Founder & Chief Operations Officer"/>
//                     </div>
//                     <div className="col-lg-3 col-sm-6">
//                         <Teamitem linkedinUrl="https://www.linkedin.com/in/niran-rehman-64697713/" teamImage="niran.png" memberN="Niran Rehman" memberd="Chairperson, Board of Directors"/>
//                     </div>
//                     <div className="col-lg-3 col-sm-6">
//                         <Teamitem linkedinUrl="https://www.linkedin.com/in/shannongrewer/" teamImage="shanon.png" memberN="Shannon Grewer" memberd="Legal Advisor, Board of Directors"/>
//                     </div>
                   
                   
//                     {/* <div className="col-lg-3 col-sm-6">
//                         <Teamitem linkedinUrl="https://www.linkedin.com/in/moiz-hussain-6b184241/" teamImage="moiz.png" memberN="Moiz Hussain" memberd="Chief Strategy & Growth Officer"/>
//                     </div> */}
//                     <div className="col-lg-3 col-sm-6">
//                         <Teamitem linkedinUrl="https://www.linkedin.com/in/usama-abid-3a3907115/" teamImage="3.png" memberN="Usama Abid" memberd="Chief Investment & Research Officer"/>
//                     </div>
//                     {/* <div className="col-lg-3 col-sm-6">
//                         <Teamitem linkedinUrl="https://www.linkedin.com/in/muhammadwaqaraqil/" teamImage="not-available.png" memberN="Muhammad Waqar Aqil" memberd="Head of Engineering & Product"/>
//                     </div> */}
//                     {/* <div className="col-lg-3 col-sm-6">
//                         <Teamitem linkedinUrl="https://www.linkedin.com/in/khizrum-ahmed-a25453163/" teamImage="not-available.png" memberN="Khizrum Ahmed" memberd="Head of Growth"/>
//                     </div> */}
                   
                   
//                     {/* <div className="col-lg-3 col-sm-6">
//                         <Teamitem linkedinUrl="https://www.linkedin.com/in/maheenriaz99/" teamImage="not-available.png" memberN="Maheen Riaz" memberd="Product Engineer"/>
//                     </div> */}
//                     <div className="col-lg-3 col-sm-6">
//                         <Teamitem linkedinUrl="https://www.linkedin.com/in/shayaansalahuddin/" teamImage="10.png" memberN="Shayaan Salahuddin" memberd="Product Marketing"/>
//                     </div>
//                     {/* <div className="col-lg-3 col-sm-6">
//                         <Teamitem linkedinUrl="https://www.linkedin.com/in/sadia-ashraf-b87b91156" teamImage="not-available.png" memberN="Sadia Ashraf" memberd="Product Designer"/>
//                     </div> */}
//                     <div className="col-lg-3 col-sm-6">
//                         <Teamitem linkedinUrl="https://www.linkedin.com/in/syed-hamza-hoda-83ab3a207/" teamImage="5.png" memberN="Hamza Hoda" memberd="Founding Product Engineer"/>
//                     </div>


//                     <div className="col-lg-3 col-sm-6">
//                         <Teamitem linkedinUrl="https://www.linkedin.com/in/saad-ali-07b4a71a1/" teamImage="9.png" memberN="Saad Ali" memberd="Strategy & Operations"/>
//                     </div>
//                     <div className="col-lg-3 col-sm-6">
//                         <Teamitem linkedinUrl="https://www.linkedin.com/in/humama-ali-0095/" teamImage="7.png" memberN="Humama Ali" memberd="Business Dev & Sales Strategy"/>
//                     </div>
//                     {/* <div className="col-lg-3 col-sm-6">
//                         <Teamitem linkedinUrl="https://www.linkedin.com/in/zeeshan-siddiqui-666779b3/" teamImage="not-available.png" memberN="Zeeshan Siddiqui" memberd="Research & Sales"/>
//                     </div> */}
//                     <div className="col-lg-3 col-sm-6">
//                         <Teamitem linkedinUrl="https://www.linkedin.com/in/syed-hamza-hoda-83ab3a207/" teamImage="8.png" memberN="Muskan Siraj" memberd="Branding & Design"/>
//                     </div>




//                     {/* <div className="col-lg-3 col-sm-6">
//                         <Teamitem linkedinUrl="https://www.linkedin.com/in/humma-muhammad-yaseen-5a1b16184/" teamImage="6.png" memberN="Huma Muhammad Yaseen" memberd="Digital Content"/>
//                     </div> */}
//                     <div className="col-lg-3 col-sm-6">
//                         <Teamitem linkedinUrl="https://www.linkedin.com/in/manastahir/" teamImage="4.png" memberN="Muhammad Anas Tahir" memberd="Compliance & Operations"/>
//                     </div>
//                     {/* <div className="col-lg-3 col-sm-6">
//                         <Teamitem linkedinUrl="#" teamImage="not-available.png" memberN="Afsana Perveen" memberd="Customer Experience"/>
//                     </div> */}









                    
//                 </div>
//             </div>
//         </section>
        
//     )
// }
// export default Team;