import React from 'react'
import Fade from 'react-reveal/Fade';

export default function ManifestSection4() {
  return (
    <React.Fragment>
    <section style={{backgroundColor:"#3881B6"}} className="seo_features_one sec_pad">
        <div className="container">
            <div className="row">
              
              

            <div className="col-lg-6 d-flex align-items-center">
                    <Fade bottom cascade>
                        <div className="seo_features_content">
                            <h6 style={{color:"#36f6e2"}} className='wow fadeInUp'>What are you waiting for? 🙌
Get started today!</h6>
                        </div>
                    </Fade>
                </div>


              
                <Fade left>
                <div className="col-lg-6">
                    <div className="">
                        <div className="round_circle"></div>
                        <div className="round_circle two"></div>

                        <div className="seo_features_content">
                             {/* <CulturePicSlider className="about-page-cultuepic-mobileview"/> */}
                        <div style={{display:"flex"}}>
                        <a href="https://apps.apple.com/us/app/ypay-invest-mutual-funds-sip/id1614206503" target='__blank'><img style={{ maxWidth:"100%",cursor:"pointer" }} src={require("../../img/product/button1.png")}/></a>
                        <a href="https://play.google.com/store/apps/details?id=com.ypayapp.pack" target='__blank'><img style={{ maxWidth:"100%",cursor:"pointer"  }} src={require("../../img/product/button2.png")}/></a>
                        
                        </div>

                        </div>

                   



                    </div>
                </div>
                </Fade>



                
             

                <Fade bottom cascade>

                <div style={{marginLeft:"20px"}}>
                <h6 style={{color:"#fff"}} className='wow fadeInUp'>Get started today!</h6>
                
                </div>
                
                </Fade>

            </div>
        </div>
    </section>
</React.Fragment>
  )
}
