import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import RewardsComponent from "../components/Rewards/Rewards.js";


const Rewards =()=>{
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area breadcrumb_area_three"  Ptitle="Rewards" Pdescription="Home" Cdescription="> Rewards"/>
            <RewardsComponent/>

            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default Rewards;