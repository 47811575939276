const ServiceData = {
    STitle: 'You SaasLand wherever your business agency',
    Service: [
        {
            id : 1,
            shape : 'icon_shape1.png',
            icon : 'ti-panel',
            text : 'Export Presets',
            description: 'Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!',
            btn: 'Read More',
            bicon: 'ti-arrow-right',
        },
        {
            id : 2,
            shape : 'icon_shape2.png',
            icon : 'ti-layout-grid2',
            text : 'Grid and Guides',
            description: 'Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!',
            btn: 'Read More',
            bicon: 'ti-arrow-right',
        },
        {
            id : 3,
            shape : 'icon_shape3.png',
            icon : 'ti-gallery',
            text : 'Prototyping',
            description: 'Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!',
            btn: 'Read More',
            bicon: 'ti-arrow-right',
        },
        {
            id : 4,
            shape : 'icon_shape4.png',
            icon : 'ti-fullscreen',
            text : 'Pixel Precision',
            description: 'Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!',
            btn: 'Read More',
            bicon: 'ti-arrow-right',
        }
    ],
    agtext1: 'Why I say old chap that is spiffing bodge, blag pardon.',
    agtext2: 'Why I say old chap that is spiffing bodge, blag pardon.',
    agtext3: 'Starkers dropped a clanger lurgy is cack excuse my French what.',
    pServiceTitle: 'Why invest with YPay?',
    pServiceDetails: 'Investments are difficult to make and manage, we solve this problem for our users through...',
    PaymentService: [
        {
            id: 1,
            image: '4.png',
            title: 'No Fixed Lock-In Period',
            description: 'With YPay, you can request a deposit or ask for withdrawal anytime.'
        },
        {
            id: 2,
            image: '1.png',
            title: 'Smart Insights',
            description: 'What does your financial month look like? Now, keep a track of it with YPay.'
        },
        {
            id: 3,
            image: '5.png',
            title: 'Free! Purchase',
            description: "YPay doesn't charge you anything for investing your money."
        },
        {
            id: 4,
            image: '2.png',
            title: 'Security',
            description: "YPay is secure with 256-bit AES encryption ensuring your data's safety."
        },
        {
            id: 5,
            image: '3.png',
            title: 'Ensuring Returns',
            description: 'At YPay, we make your money work for you. Invest so you can get a day off!'
        }
    ],
    HRService: [
        {
            id: '1',
            HRtitles : 'Export Presets',
            HRdescription: 'Bog cheeky bugger blow off only a quid grub he legged it porkies tosser young delinquent argy-bargy.',
            Hicon: 'panel',
            rclass : 'pr_70',
            iclass: 'icon_one',
        },
        {
            id: '2',
            HRtitles : 'Grid and Guides',
            HRdescription: 'Bog cheeky bugger blow off only a quid grub he legged it porkies tosser young delinquent argy-bargy.',
            Hicon: 'layout-grid2',
            rclass : 'pl_50 pr_20',
            iclass: 'icon_two',
        },
        {
            id: '3',
            HRtitles : 'Pixel Precision',
            HRdescription: 'Bog cheeky bugger blow off only a quid grub he legged it porkies tosser young delinquent argy-bargy.',
            Hicon: 'fullscreen',
            rclass : 'pl_70',
            iclass: 'icon_three',
        },
        {
            id: '4',
            HRtitles : 'Vector Editing',
            HRdescription: 'Bog cheeky bugger blow off only a quid grub he legged it porkies tosser young delinquent argy-bargy.',
            Hicon: 'vector',
            rclass : 'pr_70',
            iclass: 'icon_four',
        },
        {
            id: '5',
            HRtitles : 'Cloud Service',
            HRdescription: 'Bog cheeky bugger blow off only a quid grub he legged it porkies tosser young delinquent argy-bargy.',
            Hicon: 'cloud-down',
            rclass : 'pl_50 pr_20',
            iclass: 'icon_five',
        },
        {
            id: '6',
            HRtitles : 'Iterate at Speed',
            HRdescription: 'Bog cheeky bugger blow off only a quid grub he legged it porkies tosser young delinquent argy-bargy.',
            Hicon: 'bolt',
            rclass : 'pl_70',
            iclass: 'icon_six',
        }
    ],
    rpostblog:[
        {
            id: '01',
            image: 'new/blog/blog_10.jpg',
            ptitle: 'Time Value of Money',
            admin: 'Admin',
            date: 'April 19, 2021',
            Link:'https://hibarajput.medium.com/time-value-of-money-1867840ff22f'
        },        
        {
            id: '02',
            image: 'new/blog/blog_10.jpg',
            ptitle: 'YPay: Youth Investment Syndicate',
            admin: 'Admin',
            date: 'January 31, 2021',
            Link:'https://khizrum.medium.com/ypay-youth-investment-syndicate-1fae2340fcd2'
        },
        {
            id: '03',
            image: 'new/blog/blog_8.jpg',
            ptitle: 'AWT Investment Ltd. and YPay Financial Services Pvt. Ltd. Partner:',
            admin: 'Admin',
            date: 'January 27, 2021',
            Link:'https://sarahpanhwar.medium.com/awt-investment-ltd-and-ypay-financial-services-pvt-ltd-9b0f0f048dee'
        },
        {
            id: '04',
            image: 'new/blog/blog_7.jpg',
            ptitle: 'NiFT Pakistan and YPay Financial Services Pvt. Ltd. Partner:',
            admin: 'Admin',
            date: 'January 27, 2021',
            Link:'https://sarahpanhwar.medium.com/nift-pakistan-and-ypay-financial-services-pvt-ltd-partner-850bdd2091f5'
        },
    ],
    rpostnews:[
        {
            id: '01',
            image: 'blog-grid/news8.jpg',
            ptitle: 'YPay Financial Services on the verge of becoming the easiest investing app of Pakistan is now licensed.',
            admin: 'Admin',
            date: 'March 10, 2022',
            Link:'https://augaf.com/ypay-financial-services-on-the-verge-of-becoming-the-easiest-investing-app-of-pakistan-is-now-licensed-as-the-securities-adviser-by-the-secp/'
        },
        {
            id: '02',
            image: 'blog-grid/news1.jpeg',
            ptitle: 'Exclusive: YPay Raises Seven-Figure Pre-Seed to Launch A User-Friendly Investment Product.',
            admin: 'Admin',
            date: 'December 30, 2021',
            Link:'https://propakistani.pk/2021/12/30/exclusive-ypay-raises-six-figure-pre-seed-to-launch-a-user-friendly-investment-product/'
        },
        {
            id: '03',
            image: 'blog-grid/news3.jfif',
            ptitle: 'Pakistani startups raised $375M in 2021.',
            admin: 'Admin',
            date: 'January 03, 2022',
            Link:'https://www.globalvillagespace.com/pakistani-startups-raised-375m-in-2021/'
        },
        {
            id: '04',
            image: 'blog-grid/news4.png',
            ptitle: '2021 — the year when Pakistani startups saw an unprecedented growth spurt.',
            admin: 'Admin',
            date: 'January 3, 2022',
            Link:'https://www.dawn.com/news/1667045/2021-the-year-when-pakistani-startups-saw-an-unprecedented-growth-spurt'
        }
    ],
    Blist: [
        {
            id: '01',
            image: 'blog-grid/news8.jpg',
            btitle: 'YPay Financial Services on the verge of becoming the easiest investing app of Pakistan is now licensed as the Securities Adviser by the SECP.',
            bdescription: 'Karachi March 10 2022: Karachi-based YPay Financial Services PVT. LTD., an Investec company that enables first-time investors to start investing in mutual funds is now licensed by the Securities and Exchange Commission of Pakistan as the Securities Adviser.',
            pdate: '10',
            Month: 'March',
            newsLink:'https://augaf.com/ypay-financial-services-on-the-verge-of-becoming-the-easiest-investing-app-of-pakistan-is-now-licensed-as-the-securities-adviser-by-the-secp/'
        },
        {
            id: '02',
            image: 'blog-grid/news1.jpeg',
            btitle: 'Exclusive: YPay Raises Seven-Figure Pre-Seed to Launch A User-Friendly Investment Product.',
            bdescription: 'Karachi-based YPay Financial Services PVT. LTD., an Investec company that enables first-time investors to start investing, has raised a seven-figure pre-seed investment at the end of 2021 through US-based accelerator fund ODX (On Deck).',
            pdate: '30',
            Month: 'December',
            newsLink:'https://propakistani.pk/2021/12/30/exclusive-ypay-raises-six-figure-pre-seed-to-launch-a-user-friendly-investment-product/'
        },
        {
            id: '03',
            image: 'blog-grid/news2.png',
            btitle: 'Funding of Pakistani Startups Crosses $300 Million This Year.',
            bdescription: 'A funding frenzy in Pakistan’s startup scene this year has seen investments cross $300 million after two e-commerce companies raised fresh funds.',
            pdate: '9',
            Month: 'December',
            newsLink:'https://www.bloomberg.com/news/articles/2021-12-09/funding-of-pakistani-startups-crosses-300-million-this-year'
        },
        {
            id: '04',
            image: 'blog-grid/news3.jfif',
            btitle: 'Pakistani startups raised $375M in 2021.',
            bdescription: 'Pakistani startup businesses witnessed impressive growth in outgoing 2021 as they received historic funding worth $375 million from venture capitalists. The figure is five times higher than what the local startups generated in the whole of 2020 when they raised only $66 million. The value is two times higher than the funding raised in last six years combined.',
            pdate: '03',
            Month: 'January',
            newsLink:'https://www.globalvillagespace.com/pakistani-startups-raised-375m-in-2021/'
        },
        {
            id: '05',
            image: 'blog-grid/news4.png',
            btitle: '2021 — the year when Pakistani startups saw an unprecedented growth spurt.',
            bdescription: 'The year 2021 was when Pakistani startups finally joined the big league, collectively raising a record $365.87 million, which was not only 450 per cent higher than the $66.44m raised in 2020 but also more than the amount raised in all previous years combined.',
            pdate: '03',
            Month: 'January',
            newsLink:'https://www.dawn.com/news/1667045/2021-the-year-when-pakistani-startups-saw-an-unprecedented-growth-spurt'
        },
        {
            id: '06',
            image: 'blog-grid/news5.jpg',
            btitle: "Pakistan's startups in 2021: $350mn raised across 81 deals.",
            bdescription: 'Pakistan’s startup sector witnessed the best year in 2021, as 81 deals worth $350 million were made, according to a Deal Flow Tracker by Invest2Innovate (i2i).',
            pdate: '04',
            Month: 'January',
            newsLink:'https://www.brecorder.com/news/40144630'
        },
        {
            id: '07',
            image: 'blog-grid/news6.png',
            btitle: "YPay among Top Fintech (Financial Technology) Companies in Pakistan (2021).",
            bdescription: 'This article showcases our top picks for the best Pakistan based Fintech (Financial Technology) companies. These startups and companies are taking a variety of approaches to innovating the Fintech (Financial Technology) industry',
            pdate: '08',
            Month: 'February',
            newsLink:'https://df.media/these-are-the-top-fintech-financial-technology-companies-in-pakistan-2021/'
        },
        {
            id: '08',
            image: 'blog-grid/news7.jpg',
            btitle: "YPay Signs Agreement for NIFT ePay for Enabling Digital Payments",
            bdescription: 'YPay, a convenient and digital platform for enabling customers to invest digitally into asset management portfolios (mutual funds), has entered into an agreement with National Institutional Facilitation Technologies (NIFT), one of the largest payment processors in Pakistan for enabling digital.',
            pdate: '19',
            Month: 'January',
            newsLink:'https://propakistani.pk/2021/01/19/ypay-signs-agreement-for-nift-epay-for-enabling-digital-payments/'
        },
    ],
   
}
export default ServiceData;