import React, { Component } from "react";
import { Link } from "react-router-dom";

class CalculatorCard extends Component {
    render(){
        let{id,url, title, ptext, btn, image}=this.props;
        return(
            <div className="calculator-card-main-container col-lg-4 col-sm-6">
                <div  className="calculator-card-inner-container event_features_item text-center wow fadeInUp">
                    <img src={require ("../../img/calculators/" + image)} alt=""/>
                    <a href="/#"><h5>{title}</h5></a>
                    <p style={{ textAlign:"start" }}>{ptext}</p>

                <Link to={"/CalculatorSingle/"+ id } className="calculator-card-button">Calculate<i className="icon_plus"></i></Link>
                </div>
            </div>
        )
    }
}

export default CalculatorCard;
