import React, {Component} from 'react';
import GroupPicItem from './GroupPicItem';
import Slider from 'react-slick';
import Slide from 'react-reveal/Slide'

class GroupPicSlider extends Component{
    render(){
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            arrows: false,
            // autoplay: true,
            autoplaySpeed: 2000,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  }
                }
              ],
        };
        return(
            <section className="event_team_area ">
              <Slide right>
            <div className="">
                <div className="hosting_title security_title text-center">
                    <h2 className="wow fadeInUp" data-wow-delay="0.2s">Team YPay</h2>
                </div>
                <Slider ref={c => (this.slider = c)} className="about_page_group_pic" {...settings}>
                    <GroupPicItem image="culture4.jpg" name="John Deo" post="Wp Developer"/>
                    <GroupPicItem image="culture1.jpg" name="John Deo" post="Wp Developer"/>
                    <GroupPicItem image="culture2.jpg" name="John Deo" post="Wp Developer"/>
                    <GroupPicItem image="culture3.jpeg" name="John Deo" post="Wp Developer"/>
                </Slider>
            </div>
            </Slide>
        </section>
        )
    }
}

export default GroupPicSlider;