import React, { Component } from "react";
import Zoom from 'react-reveal/Zoom';

class PartnershipItem extends Component {
    render(){
        let{blogImage, blogTitle, pDate, desc, newsletter,zoom}= this.props;
        return(
            <Zoom zoom>
            <div className="col-sm-4">
                <a href={newsletter} target="__blank"><div className="h_blog_item newsletter-partnership">
                    <div className="h_blog_content newsletter-partnership-text-container">
                        {/* <a  className="post_time"><i className="icon_clock_alt"></i>{pDate}</a> */}
                        <a ><h3 style={{ color:"#04638b",fontWeight:"700" }}>{blogTitle}</h3></a>
                        <div className="post-info-bottom">
                                <p>{desc}</p>

                        </div>
                    </div>
                    <a ><img style={{ borderRadius:"15px" }} src={require ("../../img/Newsletter/" + blogImage)} alt="blog"/></a>
                </div></a>
            </div>
            </Zoom>
        )
    }
}
export default PartnershipItem;