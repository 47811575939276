const RiskData = {


      RiskProfilePersonalData:[

        {
          question:	"1). Age",
	        options:[
            {
              name:"A. Under 30",
              value:"10",
              group:"1"
            },
            {
              name:"B. 30-40",
              value:"7",
              group:"1"
            },
            {
              name:"C. 40-50",
              value:"5",
              group:"1"
            },
            {
              name:"D. 50 or older",
              value:"3",
              group:"1"
            }
          ]
        },



        {
          question:	"2). Marital Status",
	        options:[
            {
              name:"A. Married",
              value:"5",
              group:"2"
            },
            {
              name:"B. Single",
              value:"10",
              group:"2"
            }
          ]
        },


        {
          question:	"3). No. of Dependants",
	        options:[
            {
              name:"A. 1-3",
              value:"7",
              group:"3"
            },
            {
              name:"B. None",
              value:"10",
              group:"3"
            },
            {
              name:"C. 4-5",
              value:"5",
              group:"3"
            },
            {
              name:"D. More than 5",
              value:"3",
              group:"3"
            }
          ]
        },


        {
          question:	"4). Education",
	        options:[
            {
              name:"A. Phd and above",
              value:"10",
              group:"4"
            },
            {
              name:"B. Masters/ Post graduate",
              value:"7",
              group:"4"
            },
            {
              name:"C. Graduate",
              value:"4",
              group:"4"
            },
            {
              name:"D. Intermediate",
              value:"2",
              group:"4"
            },
            {
              name:"E. Matriculation or below",
              value:"0",
              group:"4"
            }
          ]
        },


        {
          question:	"5). Residential Status",
	        options:[
            {
              name:"A. Self-Owned",
              value:"10",
              group:"5"
            },
            {
              name:"B. Spouse / Parents Owned",
              value:"7",
              group:"5"
            },
            {
              name:"C. Rented",
              value:"4",
              group:"5"
            }
          ]
        },



      ],




      RiskProfileInvestmentData:[

        {
          question:	"6). Suppose that you have invested Rs 5000/-, How soon do you expect to withdraw more than 30% of the amount invested?",
	        options:[
            {
              name:"A. Less than 1 year",
              value:"3",
              group:"6"
            },
            {
              name:"B. 1-3 years",
              value:"5",
              group:"6"
            },
            {
              name:"C. 4-6 years",
              value:"7",
              group:"6"
            },
            {
              name:"D. 7-9 years",
              value:"10",
              group:"6"
            }
          ]
        },



        {
          question:	"7). Which statement best describes your knowledge of investments?",
	        options:[
            {
              name:"A. I have very little knowledge of investments and financial markets.",
              value:"5",
              group:"7"
            },
            {
              name:"B. I have a moderate level of knowledge of investments and financial markets.",
              value:"7",
              group:"7"
            },
            {
              name:"C. I have extensive investment knowledge; understand different investment products and follow financial markets closely.",
              value:"10",
              group:"7"
            }
          ]
        },


        {
          question:	"8). What is your primary goal for this portfolio: ",
	        options:[
            {
              name:"A. I want to keep the money I have invested safe from short-term losses or readily available for short-term needs",
              value:"10",
              group:"8"
            },
            {
              name:"B. I want to generate a steady stream of income from my investments and I am less concerned about growing the value of my investments",
              value:"7",
              group:"8"
            },
            {
              name:"C. I want to generate some income with some opportunity for the investments to grow in value.",
              value:"5",
              group:"8"
            },
            {
              name:"D. I want to generate long-term growth from my investments.",
              value:"3",
              group:"8"
            }
          ]
        },



      ],








      RiskProfileRiskData:[

        {
          question:	"9). What is your monthly income (from all sources)? ",
	        options:[
            {
              name:"A. Less than 50,000",
              value:"3",
              group:"9"
            },
            {
              name:"B. 50,000-99,999",
              value:"5",
              group:"9"
            },
            {
              name:"C. 100,000-149,999",
              value:"7",
              group:"9"
            },
            {
              name:"D. 150,000 and more",
              value:"10",
              group:"9"
            }
          ]
        },



        {
          question:	"10). Your current and future income sources are: ",
	        options:[
            {
              name:"A. Stable",
              value:"10",
              group:"10"
            },
            {
              name:"B. Somewhat stable",
              value:"5",
              group:"10"
            },
            {
              name:"C. Unstable",
              value:"0",
              group:"10"
            }
          ]
        },


        {
          question:	"11). Approximately what percentage of your total savings you would invest ?",
	        options:[
            {
              name:"A. Less than 25%",
              value:"10",
              group:"11"
            },
            {
              name:"B. 25% - 50%",
              value:"7",
              group:"11"
            },
            {
              name:"C. 51% - 75%",
              value:"5",
              group:"11"
            },
            {
              name:"D. More than 75%",
              value:"3",
              group:"11"
            }
          ]
        },



        {
          question:	"12). How would you classify your overall financial situation? ",
	        options:[
            {
              name:"A. No savings and high amount of debt",
              value:"0",
              group:"12"
            },
            {
              name:"B. Low savings and a moderate amount of debt",
              value:"3",
              group:"12"
            },
            {
              name:"C. Some savings and some debt",
              value:"5",
              group:"12"
            },
            {
              name:"D. Some savings and no debt",
              value:"7",
              group:"12"
            },
            {
              name:"E. High savings and no debt",
              value:"10",
              group:"12"
            }
          ]
        },


       



      ],









      RiskProfileAttitudeData:[

        {
          question:	"13). In making financial and investment decisions you are:",
	        options:[
            {
              name:"A. Very conservative and try to minimize risk and avoid the possibility of any loss",
              value:"3",
              group:"13"
            },
            {
              name:"B.  Conservative but willing to accept a small amount of risk ",
              value:"5",
              group:"13"
            },
            {
              name:"C. Willing to accept a moderate level of risk and tolerate losses to achieve potentially higher returns",
              value:"7",
              group:"13"
            },
            {
              name:"D. Aggressive and typically take on significant risk and are willing to tolerate large losses for the potential of achieving higher returns ",
              value:"10",
              group:"13"
            }
          ]
        },



        {
          question:	"14). How much of a decline for investment portfolio could you tolerate in your in a 12 months period? Assuming that you have invested 10,000",
	        options:[
            {
              name:"A. I could not tolerate any loss",
              value:"1",
              group:"14"
            },
            {
              name:"B. -300 PKR (-3%)",
              value:"3",
              group:"14"
            },
            {
              name:"C. -1,000 PKR (-10%)",
              value:"5",
              group:"14"
            },
            {
              name:"D. -2,000 PKR (-20%)",
              value:"7",
              group:"14"
            },
            {
              name:"E. More than -2,000 PKR (more than -20%)",
              value:"10",
              group:"14"
            }
          ]
        },


        {
          question:	"15). When you are faced with a major financial decision, are you more concerned about the possible losses or the possible gains? ",
	        options:[
            {
              name:"A. Always the possible losses",
              value:"3",
              group:"15"
            },
            {
              name:"B. Usually the possible losses",
              value:"5",
              group:"15"
            },
            {
              name:"C. Usually the possible gains ",
              value:"7",
              group:"15"
            },
            {
              name:"D. Always the possible gains",
              value:"10",
              group:"15"
            }
          ]
        },



        {
          question:	"16). From September 2008 through November 2008, North American stock markets lost over 30%. If you currently own an investment that lost over 30% in 3 months you would:",
	        options:[
            {
              name:"A. Sell all of the remaining investment to avoid further losses ",
              value:"3",
              group:"16"
            },
            {
              name:"B. Sell a portion of the remaining investment to protect some of your capital",
              value:"5",
              group:"16"
            },
            {
              name:"C. Hold onto the investment and not sell any of the investment in the hopes of higher future returns",
              value:"7",
              group:"16"
            },
            {
              name:"D. Buy more of the investment at lower prices	",
              value:"10",
              group:"16"
            }
          ]
        },


       



      ],















};
export default RiskData;