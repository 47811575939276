import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
/*------ Pages-----*/
import PaymentProcessing from "./Pages/Payment-processing";


import Bloglist from "./Pages/Bloglist";
import BlogSingle from "./Pages/BlogSingle";
import Contact from "./Pages/Contact";
import ScrollToTopRoute from "./ScrollToTopRoute";


import Faq from "./Pages/Faq";

import Privacy from "./Pages/Privacy";

import Team from "./Pages/Team";

import BlogGridPage from "./Pages/BlogGridPage";
import Careers from "./Pages/Careers";
import NotFound from "./Pages/404";
import TaxBenefit from "./Pages/TaxBenefit";
import About from "./Pages/About";
import RiskProfile from "./Pages/RiskProfile";
import TermsAndCondition from "./Pages/TermsAndCondition";
import Mashwara from "./Pages/Mashwara";
import AccountFormat from "./Pages/Resources/AccountFormat";
import YPAY101 from "./Pages/YPAY101"
import NewsLetter from "./Pages/NewLetter";
import CalculatorsPage from "./Pages/Calculators";
import Rewards from "./Pages/Rewards"
import CalculatorSingle from "./Pages/CalculatorSingle";
import DownloadApp from "./DownloadApp"
import Product from "./Pages/Product";
import MoneyCircle from "./Pages/MoneyCircle";
import Manifest from "./Pages/Manifest";

class App extends Component {
  componentDidMount() {
    this.props.hideLoader();
  }
  render() {
    return (
      <Router>
        <Switch>
          <ScrollToTopRoute exact={true} path={"/"} component={PaymentProcessing} />
       


        
          <ScrollToTopRoute path="/news" component={Bloglist} />
          <ScrollToTopRoute path="/BlogSingle/:id" component={BlogSingle} />
          <ScrollToTopRoute path="/blogs" component={BlogGridPage} />
          <ScrollToTopRoute path="/contact" component={Contact} />
          <ScrollToTopRoute path="/privacypolicy" component={Privacy} />
          <ScrollToTopRoute path="/team" component={Team} />
          <ScrollToTopRoute path="/risk-profile" component={RiskProfile} />

          <ScrollToTopRoute path="/about" component={About} />

          <ScrollToTopRoute path="/careers" component={Careers} />
          <ScrollToTopRoute path="/taxupdate" component={TaxBenefit} />

          <ScrollToTopRoute path="/mashwara" component={Mashwara} />

          <ScrollToTopRoute path="/faqs" component={Faq} />
          <ScrollToTopRoute path="/YPay101" component={YPAY101} />
          {/* <ScrollToTopRoute path="/newsletter" component={NewsLetter} /> */}
          {/* <ScrollToTopRoute path="/calculators" component={CalculatorsPage} /> */}
          {/* <ScrollToTopRoute path="/CalculatorSingle/:id" component={CalculatorSingle} /> */}

          <ScrollToTopRoute path="/rewards" component={Rewards} />


          <ScrollToTopRoute path="/termsandcondition" component={TermsAndCondition} />

          <ScrollToTopRoute path="/resources/accountformat" component={AccountFormat} />

          <ScrollToTopRoute path="/download" component={DownloadApp} />

          <ScrollToTopRoute path="/product" component={Product} />
          <ScrollToTopRoute path="/moneycircle" component={MoneyCircle} />
          <ScrollToTopRoute path="/manifest" component={Manifest} />


          <ScrollToTopRoute component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default App;
