import React,{Component} from "react";
import "./RiskProfile.css";
import RiskData from "./RiskData.js";

class RiskProfile extends Component{
  constructor(props) {
    super(props);
    this.state = {
      data:["RiskProfilePersonalData","RiskProfileInvestmentData","RiskProfileRiskData","RiskProfileAttitudeData"],
      current:0,
      displayResult:false
    };
  }

  handleChange = e => {
    const { name, value } = e.target;

    this.setState({
      [name]: value
    });
    // console.log(this.state);
  };


  handleNext = e => {

    if(this.state.current < this.state.data.length-1){
      window.scrollTo(0, 0);
    this.setState({
      current:this.state.current + 1

    })
  }
  }

  handlePrev = e => {

    if(this.state.current > 0){
      window.scrollTo(0, 0);

    this.setState({
      current:this.state.current - 1

    })
  }
  }



  handleSubmit = e => {
   
    let personaldetails = (this.state["1"] ? this.state["1"] * 0.03 : 0) + (this.state["2"] ? this.state["2"] * 0.01 : 0) + (this.state["3"] ? this.state["3"] * 0.02 : 0) + (this.state["4"] ? this.state["4"] * 0.03 : 0) + (this.state["5"] ? this.state["5"] * 0.01 : 0) 
    
    let investmentdetails = (this.state["6"] ? this.state["6"] * 0.06 : 0) + (this.state["7"] ? this.state["7"] * 0.07 : 0) + (this.state["8"] ? this.state["8"] * 0.07 : 0)

    let riskdetails = (this.state["9"] ? this.state["9"] * 0.15 : 0) + (this.state["10"] ? this.state["10"] * 0.15 : 0) + (this.state["11"] ? this.state["11"] * 0.10 :0) + (this.state["12"] ? this.state["12"] * 0.10 : 0)

    let riskattitude = (this.state["13"] ? this.state["13"] * 0.05 :0) + (this.state["14"] ? this.state["14"] * 0.10 :0) + (this.state["15"] ? this.state["15"] * 0.025 :0) + (this.state["16"] ?this.state["16"] * 0.025 :0)


    let total = personaldetails + investmentdetails + riskdetails + riskattitude
    let message = "";
    let rank = "";

    if(total >= 9.5){
      rank = "Prime";
      message = "This rating denotes the lowest expectation of investment risk, due to exceptionally strong capacity of the customer for managing financial commitments. This capacity is unlikely to be affected by adverse economic situations."
    }

    else if(total >= 9 && total < 9.5){
      rank = "Strong";
      message = "This rating differs from '1' only in a small degree. It denotes a very low expectation of investment risk. The investing capacity of the customer is very strong."
    }

    
    else if(total >= 8 && total < 9){
      rank = "Good";
      message = "This rating denotes a low expectation of investment risk due to strong managing capacity of customer. However, this capacity may be vulnerable to changes in circumstances."
    }



    else if(total >= 7 && total < 8){
      rank = "Above Average";
      message = "This rating indicates that there is currently a low expectation of investment risk. The capacity for timely managing is reasonably considered , but adverse changes in circumstances are more likely to impair this capacity."
    }



    else if(total >= 6 && total < 7){
      rank = "Average";
      message = "This rating indicates that there is a possibility of investment risk, particularly as a result of adverse asset's price changes over time."
    }

    else if(total >= 5.5 && total < 6){
      rank = "Below Average";
      message = "This rating indicates that significant investment risk is present, but there is a limited margin of safety. Capacity for continued managing is contingent upon sustained, favorable circumtances."
    }

    else if(total >= 3.5 && total < 5.5){
      rank = "Watch List";
      message = "This rating denotes that the customer exhibits initial signals of deteriorating financial conditions due to adverse internal or economic circumstances having an unfavorable impact on its investment management capacity."
    }

    else if(total >= 3 && total < 3.5){
      rank = "Weak";
      message = "This rating indicates a greater degree of deteriorating financial condition of the customer due to adverse internal or economic circumstances.  The inventment management capacity of the customer is severly hampered."
    }


    else if(total < 3){
      rank = "Extremely Weak";
      message = "This rating indicates that mismanagement is a real possibility. Capacity for meeting financial commitments is extremely vulnerable."
    }


    this.setState({
      displayResult:true,
      result: {
        rank:rank,
        message:message,
        score:total
      }
    })

    // console.log(this.state)

  }



  render(){



    return <div className="risk-profile-container">
    {/* <h1>Risk Profile</h1> */}

    
    {
    
    this.state.displayResult ?


      <div  className="risk-profile-subcontainer">

      <div >
      <h5>Score</h5>
      <h6>{this.state.result.score.toFixed(2)}</h6>
      <h5>Risk Tolerance</h5>
      <h6>{this.state.result.rank}</h6>
      <h5>Feedback</h5>
      <h6>{this.state.result.message}</h6>
      </div>
      <div style={{borderBottom:"2px solid #04638b"}}></div>
      </div>









    :
    
    RiskData[this.state.data[this.state.current]].map((item, index) => {
      
      
      return  <div key={index} className="risk-profile-subcontainer">

      <div style={{textAlign:"start"}}>
      <h5>{item.question}</h5>

      </div>

{ item.options.map((subitem, index) => {

  return  <div key={index}><div class="input-group">
<div class="text-group-field pickup-day choose-time">
 <div class="inner-block">
   <div>
   <input id={subitem.name} value={subitem.value} checked={this.state[subitem.group] == subitem.value} onChange={this.handleChange} class="radio-custom input-group-field" name={subitem.group} type="radio"/>
   </div>
   <div>
   <label for={subitem.name} class="radio-custom-label"><span>{subitem.name}</span></label>
  </div>
 </div>
</div>
</div>
 <div style={{borderBottom:"2px solid #04638b"}}></div>
</div>

})}






    </div>


})}

{
  this.state.displayResult ? null :

  <div style={{marginTop:"50px",marginBottom:"50px"}}>

{this.state.current > 0 ? <button className="pay_btn pay_btn_two" onClick={this.handlePrev}>Previous</button>: null}

{this.state.current == 3 ? <button className="pay_btn pay_btn_two" onClick={this.handleSubmit}>Submit</button> : <button className="pay_btn pay_btn_two" onClick={this.handleNext}>Next</button>}


</div>
}
</div>




  }




}


export default RiskProfile;