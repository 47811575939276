import React, {Component} from 'react';
import {Link} from 'react-router-dom'

class Bloglistitem extends Component{
    render(){
        let ServiceData = this.props.ServiceData;
        return(
            <>
                        {/* Tax Benefit News */}

                            <div className="blog_list_item mb_50" >
                                <img className="img-fluid" src={require("../../img/blog-grid/Tax-benefit-cover.png")} alt=""/>
                                <div className="blog_content">
                                    <div className="post_date">
                                        <h2>7 <span>July</span></h2>
                                    </div>
                                    <div className="entry_post_info">
                                        <a href="#" target="_blank"> By: Admin</a>
                                        <a href="#" target="_blank">YPay</a>
                                    </div>
                                    <a href="#" target="_blank">
                                        <h5 className="f_p f_size_20 f_500 t_color mb_20">Tax Update</h5>
                                    </a>
                                    <p className="f_400 mb_20">Tax amendments related to Mutual Funds</p>
                                    <Link
                                        to="/taxupdate"
                                        className="learn_btn_two"
                                        >
                                        Read More <i className="ti-arrow-right"></i>
                                        </Link>
                                </div>
                            </div>








                {
                    ServiceData.Blist.map(post=>{
                        return(
                            <div className="blog_list_item mb_50"  key={post.id}>
                                <img className="img-fluid" src={require("../../img/" + post.image)} alt=""/>
                                <div className="blog_content">
                                    <div className="post_date">
                                        <h2>{post.pdate} <span>{post.Month}</span></h2>
                                    </div>
                                    <div className="entry_post_info">
                                        <a href={post.newsLink} target="_blank"> By: Admin</a>
                                        <a href={post.newsLink} target="_blank">YPay</a>
                                    </div>
                                    <a href={post.newsLink} target="_blank">
                                        <h5 className="f_p f_size_20 f_500 t_color mb_20">{post.btitle}</h5>
                                    </a>
                                    <p className="f_400 mb_20">{post.bdescription}</p>
                                    <a href={post.newsLink} target="_blank" className="learn_btn_two">Read More <i className="ti-arrow-right"></i></a>
                                </div>
                            </div>
                        )
                    })
                }
            </>
        )
    }
}
export default Bloglistitem;