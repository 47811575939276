import React from 'react';
import Sectitle from '../Title/Sectitle';
import BlogGridItem from '../Blog/BlogGridItem';
import { Fade } from 'react-reveal';
import data from './BlogData';

const HostingBlog =()=> {
    return(
        <section id="landing-page-blog-section" className="h_blog_area sec_pad">
            <div className="container">
                <Sectitle Title="Our Latest Blogs" TitleP="Our fun and influential learning blogs and Mashwara sessions go beyond financial education to include learning the theory, practicing investing skills, and getting daily feedback on the performance and progress of investing with YPay Financial ltd." sClass="hosting_title text-center"/>
                
                <Fade left>

                <div className="row">
                    <div  style={{ marginTop:"20px" }} className="col-lg-12 blog_grid_info">
                        <div className="row">


                        {   data && data.slice(0,3).map((e,i) => {
                        return   <BlogGridItem index={i} key={i} date={e.date} month={e.month} image={e.image} Title={e.Title} description={e.description}
                        btn={e.btn} comment={e.comment} 
                        content ={e.content}
                        />
                    })
                                        
                    }

                </div>
            </div>
            </div>
            </Fade>
            </div>
        </section>
    )
}

export default HostingBlog;