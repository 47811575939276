import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import Breadcrumb from "../components/Breadcrumb";
// import Partner from '../components/Partner';
import FooterTwo from "../components/Footer/FooterTwo";
import FooterData from "../components/Footer/FooterData";
// import Features from '../components/Features/Features';

import Skeleton from "react-loading-skeleton";


import ProductSection1 from "../components/Product/ProductSection1";
import ProductSection2 from "../components/Product/ProductSection2";
import ProductSection3 from "../components/Product/ProductSection3";
import ProductSection4 from "../components/Product/ProductSection4";

const Product = () => {
  return (
    <div className="body_wrapper">
      <CustomNavbar
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu ml-auto mr-auto"
      />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area"
        imgName="breadcrumb/banner_bg.png"
        Ptitle="Our Product"
        Pdescription="Home"
        Cdescription="> Our Product"
      />
      {/* <EventFeatures /> */}

     <ProductSection1/>
     <ProductSection2/>
     <ProductSection3/>
     <ProductSection4/>

      

      <FooterTwo FooterData={FooterData} />
    </div>
  );
};
export default Product;
