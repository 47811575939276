import React, {Component} from 'react';
import FooterData from '../../Footer/FooterData';

class RewardsItem extends Component {
    render(){
        let{image,name, post} = this.props;
        return(
            <div className="item" style={{ padding:"0px" }}>
                <div className="e_team_img">
                <img src={require ("../../../img/about/" + image)} alt=""/>

                    {/* <h3 style={{ textAlign:"left" }}>Lorem ipsum is placeholder </h3> */}
                    {/* <h6 style={{ textAlign:"left" }}>By Abc</h6> */}
                    {/* <p style={{ textAlign:"left" }}> */}
                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip */}
                    {/* </p> */}
                </div>
                
            </div>
        )
    }
}

export default RewardsItem;