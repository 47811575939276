import React from 'react';
import Sectitle from "../../Title/Sectitle";
import companyPdf from "../../../img/YPay_Company_Profile_Website.pdf";
import licensePdf from "../../../img/license.pdf";
import "./CloudServiceTab.css";

const CloudServiceTab =()=>{
    return(
        <section className="software_service_area sec_pad">
            <div className="container">
                <Sectitle Title="Our Company" TitleP="Learn more about our company profile"
                 sClass="sec_title text-center mb_70"/>
                <div className="row">
                    <div className="col-lg-3 col-md-3">
                        <ul className="nav nav-tabs software_service_tab" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="de-tab" data-toggle="tab" href="#de" role="tab" aria-controls="de" aria-selected="true">Company Details</a>
                            </li>
                       
                        </ul>
                    </div>
                    <div className="col-lg-9 col-md-9">
                        <div className="tab-content software_service_tab_content">
                            <div className="tab-pane fade show active" id="de" role="tabpanel" aria-labelledby="de-tab">
                                <div className="row" style={{marginLeft:"0px",marginRight:"0px"}}>


                                    <div className='about-page-cloudservicetab' >
                                        <div className='about-page-cloudservicetab-row-container'>
                                            <p >Legal Name:</p>
                                            <p >YPay Financial Services (Private) Limited.</p>
                                        </div>

                                        <div className='about-page-cloudservicetab-row-container'>
                                            <p >CUIN:</p>
                                            <p >0165776</p>

                                            </div>

                                            <div className='about-page-cloudservicetab-row-container'>

                                            <p >Company National Tax Number:</p>
                                            <p >8063584</p>
                                            </div>


                                            <div className='about-page-cloudservicetab-row-container'>

                                            <p >Regulators of the Company:</p>
                                            <p >Securities and Exchange Commission of Pakistan</p>
                                            </div>

                                            <div className='about-page-cloudservicetab-row-container'>

                                            <p >Auditors of the Company:</p>
                                            <p >A.G Habib & Co.</p>
                                            </div>

                                            <div className='about-page-cloudservicetab-row-container'>

                                            <p >YPay Financial Services' Securities Advisory Services License:</p>
                                            <p style={{color:"#04638b",fontWeight:"500"}}><a style={{color:"#04638b",fontWeight:"500"}} href={licensePdf} target="_blank">Download</a></p>
                                            </div>

                                            <div className='about-page-cloudservicetab-row-container'>

                                            <p >YPay Financial Services' Corporate Profile:</p>
                                            <p style={{color:"#04638b",fontWeight:"500"}}><a  style={{color:"#04638b",fontWeight:"500"}} href={companyPdf} target="_blank">Download</a></p>
                                        </div>

                                    </div>

                            
                                </div>
                            </div>
                            <div className="tab-pane fade" id="secure" role="tabpanel" aria-labelledby="secure-tab">
                                <div className="row" style={{marginLeft:"0px",marginRight:"0px"}}>
                                 
          

                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default CloudServiceTab;