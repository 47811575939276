import React, {Component} from 'react';
import YPAY101Item from './YPAY101Item';
import Slider from 'react-slick';

class YPAY101Slider extends Component{
    render(){
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            arrows: false,
            // autoplay: true,
            autoplaySpeed: 2000,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  }
                }
              ],
        };
        return(
            <section className="event_team_area sec_pad">
            <div className="">
                <div className="hosting_title security_title text-center">
                    <h2 className="wow fadeInUp" data-wow-delay="0.2s"><span>Team</span> YPay</h2>
                </div>
                <Slider ref={c => (this.slider = c)} className="ypay_101_slider" {...settings}>
                    <YPAY101Item image="team1.jpg" name="John Deo" post="Wp Developer"/>
                    <YPAY101Item image="team2.jpg" name="Jason Response" post="Graphic Design"/>
                    <YPAY101Item image="team3.jpg" name="John Deo" post="Wp Developer"/>
                    <YPAY101Item image="team1.jpg" name="John Deo" post="Wp Developer"/>
                    <YPAY101Item image="team2.jpg" name="John Deo" post="Wp Developer"/>
                </Slider>
            </div>
        </section>
        )
    }
}

export default YPAY101Slider;