import React, {useEffect} from "react";

const DownloadApp = () =>{

    useEffect(()=>{
    const userAgent = navigator.userAgent;

    if(/android/i.test(userAgent)){

        let data = {
            os:"android",
            version: userAgent,
            date: new Date().toLocaleString()
        }

        let xmlhttp = new XMLHttpRequest();
        xmlhttp.open("POST", "https://hooks.zapier.com/hooks/catch/12095291/3t9n7gz/", true);
        xmlhttp.onreadystatechange = function () { //Call a function when the state changes.
        if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
        // console.log(xmlhttp.responseText);       
        window.location.replace("https://play.google.com/store/apps/details?id=com.ypayapp.pack")
        }
        };
        xmlhttp.send(JSON.stringify(data));
    }

    
    else if(/iPad|iPhone|iPod/i.test(userAgent)){

        let data = {
            os:"ios",
            version: userAgent,
            date: new Date().toLocaleString()
        }

        let xmlhttp = new XMLHttpRequest();
        xmlhttp.open("POST", "https://hooks.zapier.com/hooks/catch/12095291/3t9n7gz/", true);
        xmlhttp.onreadystatechange = function () { //Call a function when the state changes.
        if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
        // console.log(xmlhttp.responseText);       
        window.location.replace("https://apps.apple.com/us/app/ypay-invest-mutual-funds-sip/id1614206503")
        }
        };
        xmlhttp.send(JSON.stringify(data));
        
    }

    else{
        window.location.replace("https://ypayfinancial.com")
    }

    },[])


    return <p></p>

}

export default DownloadApp