import React, {Component} from 'react';

class Newsrightsidebar extends Component{
    render(){
        // console.log(this.props)
        let ServiceData = this.props.ServiceData;
        return(
            <div className="blog-sidebar">
                {/* <div className="widget sidebar_widget widget_search">
                    <form action="#" className="search-form input-group">
                        <input type="search" className="form-control widget_input" placeholder="Search"/>
                        <button type="submit"><i className="ti-search"></i></button>
                    </form>
                </div> */}
                <div className="widget sidebar_widget widget_recent_post mt_60">
                    <div className="widget_title">
                        <h3 className="f_p f_size_20 t_color3">{this.props.heading}</h3>
                        <div className="border_bottom"></div>
                    </div>
                    {
                        ServiceData.map(post=>{
                            return(
                                <div className="media post_item" key={post.id}>
                                    <img style={{width:"90px",height:"80px"}} src={require('../../img/' + post.image)} alt=""/>
                                    <div className="media-body">
                                        <a href={post.Link} target="_blank">
                                            <h3 className="f_size_16 f_p f_400">{post.ptitle}</h3>
                                        </a>
                                        <div className="entry_post_info">
                                            By: <a href={post.Link} target="_blank">{post.admin}</a>
                                            <a href={post.Link} target="_blank">{post.date}</a>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                {this.props.showCategories ?  <div className="widget sidebar_widget widget_categorie mt_60">
                    <div className="widget_title">
                        <h3 className="f_p f_size_20 t_color3">Categories</h3>
                        <div className="border_bottom"></div>
                    </div>
                    <ul className="list-unstyled">
                        <li> <a href=".#"><span>Financial Literacy</span><em>({this.props.categories["FL"]})</em></a> </li>
                        <li> <a href=".#"><span>Partnerships</span><em>({this.props.categories["P"]})</em></a> </li>
                    </ul>
                </div>
                : null    
            }
                {/* <div className="widget sidebar_widget widget_tag_cloud mt_60">
                    <div className="widget_title">
                        <h3 className="f_p f_size_20 t_color3">Tags</h3>
                        <div className="border_bottom"></div>
                    </div>
                    <div className="post-tags">
                        <a href=".#">SaasLand</a>
                        <a href=".#">Web Design</a>
                        <a href=".#">Saas</a>
                        <a href=".#">Cooling System</a>
                        <a href=".#">Corporate</a>
                        <a href=".#">Software</a>
                        <a href=".#">Landing</a>
                        <a href=".#">Wheels</a>
                    </div>
                </div> */}
            </div>
        )
    }
}
export default Newsrightsidebar;