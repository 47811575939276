import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import Breadcrumb from "../components/Breadcrumb";
// import Partner from '../components/Partner';
import FooterTwo from "../components/Footer/FooterTwo";
import FooterData from "../components/Footer/FooterData";
// import Features from '../components/Features/Features';

import Skeleton from "react-loading-skeleton";


import MoneyCircle1 from "../components/MoneyCircle/MoneyCircle1";
import MoneyCircle2 from "../components/MoneyCircle/MoneyCircle2";
import MoneyCircle3 from "../components/MoneyCircle/MoneyCircle3";
import MoneyCircle4 from "../components/MoneyCircle/MoneyCircle4";


const MoneyCircle = () => {
  return (
    <div className="body_wrapper">
      <CustomNavbar
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu ml-auto mr-auto"
      />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area"
        imgName="breadcrumb/banner_bg.png"
        Ptitle="Money Circle"
        Pdescription="Home"
        Cdescription="> Money Circle"
      />
      {/* <EventFeatures /> */}

     <MoneyCircle1/>
     <MoneyCircle2/>
     <MoneyCircle3/>

     <div style={{marginBottom:"30px",textAlign:"center"}}>
     <h3  className="wow fadeInUp">To know more about Money Circle <a href='/faqs?moneycircle' ><span>Click Here</span> </a></h3>
      </div>

     <MoneyCircle4/>
      

      <FooterTwo FooterData={FooterData} />
    </div>
  );
};
export default MoneyCircle;
