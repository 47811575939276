import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import Breadcrumb from "../components/Breadcrumb";
// import Partner from '../components/Partner';
import FooterTwo from "../components/Footer/FooterTwo";
import FooterData from "../components/Footer/FooterData";
// import Features from '../components/Features/Features';

import Skeleton from "react-loading-skeleton";


import ManifestSection1 from "../components/Manifest/ManifestSection1";
import ManifestSection2 from "../components/Manifest/ManifestSection2";
import ManifestSection3 from "../components/Manifest/ManifestSection3";
import ManifestSection4 from "../components/Manifest/ManifestSection4";

const Manifest = () => {
  return (
    <div className="body_wrapper">
      <CustomNavbar
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu ml-auto mr-auto"
      />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area"
        imgName="breadcrumb/banner_bg.png"
        Ptitle="Manifest"
        Pdescription="Home"
        Cdescription="> Manifest"
      />
      {/* <EventFeatures /> */}

     <ManifestSection1/>
     <ManifestSection2/>
     <ManifestSection3/>
     <ManifestSection4/>

      

      <FooterTwo FooterData={FooterData} />
    </div>
  );
};
export default Manifest;
