import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import Bloglists from '../components/Blog/Bloglists'
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import { ElfsightWidget } from 'react-elfsight-widget';

const Bloglist = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="News" Pdescription="Home" Cdescription="> News"/>
            <Bloglists/>
            <div style={{margin:"20px"}}>
            <h2 style={{textAlign:"center",marginBottom:"40px"}}>Follow us on social media and stay tuned!</h2>
            <ElfsightWidget widgetID="23e718d2-1f2c-48ef-9064-37282ffcea67" />;
            </div>
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default Bloglist;