import React, {Component} from 'react';
import axios from "../axios";
import {Link} from 'react-router-dom';
import Fade from 'react-reveal/Fade';

class Contact2 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            subject: '',
            message: '',
            success: false,
            error: false,
            current:1
        }
        this.handleChange = this.handleChange.bind(this)

    }
    
    handleChange = e => {
        this.setState({[e.target.name]: e.target.value})
    }

    componentDidMount(){
        window.scrollTo(0, 100);
    }

    submitForm = () => {


      console.log(this.state)  

        axios.post('https://networks.ypayfinancial.com/api/mail/contact.php',{

                "email":this.state.email,
                "name": this.state.name,
                "subject": this.state.subject,
                "response":this.state.message
            }
          )
            .then(response => {
                if(response.data.response == ""){
                console.log(response)
                this.setState({success:true,error:false,name:"",email:"",subject:"",message:"",current:1})
                }
                else{
                    this.setState({success:false,error:true,name:"",email:"",subject:"",message:""})
                }
          })
            .catch(err => {
              console.log(err)
              this.setState({error:true,name:"",email:"",subject:"",message:""})

            })
  
    }

    render(){
        // const {name, email, subject, message, emailStatus} = this.state;
        console.log(this.props)
        return(
            <section className="contactpage-main-section sec_pad">

          {/* <div style={{ borderTop:"4px solid blue",width:`${this.state.current == 1 ? "33.3%" : this.state.current == 2 ? "66.6%" : "100%"}` }}></div> */}
                
            <Fade bottom cascade>

                <div  className="container contactpage-container" >
                  
                <div class="contactpage-outer-box">
{
    this.state.current == 1 ?

                    <div class="contactpage-inner-box">
                        
                        <h4>Hello! 👋 What's your name?  </h4>
                        <div className="contactpage-input-wrapper">
                        <div className="input-data">
                            <input type="text" value={this.state.name} onChange={(e)=>this.setState({name:e.target.value})} />
                            <div className="underline"></div>
                        </div>
                    </div>
                   {this.state.success && this.state.error == false ? <h5 style={{ marginTop:"20px",color:"green" }}>Message sent successfuly.</h5> :null}
                    </div>

                :

                this.state.current == 2 
                
                ?

            <Fade bottom cascade>
                <div class="contactpage-inner-box">
                        


                <h4>What's your email? 📫 </h4>
                <div className="contactpage-input-wrapper">
                <div className="input-data">
                    <input type="email" value={this.state.email} onChange={(e)=>this.setState({email:e.target.value})} />
                    <div className="underline"></div>
                </div>
            </div>


            </div>
            </Fade>

            :
            <Fade bottom cascade>
            
            <div class="contactpage-inner-box">
                
                        

            <h4>How can we help? 🤗 </h4>
            <div className="contactpage-input-wrapper">
            <div className="input-data">

                <textarea  name="How can we help? 🤗 " autoComplete="on" className="contactpage-textfield" id="field-text-e7kl" rows="3"  style={{ overflow: "hidden", overflowWrap: "break-word", height: "107px"}} value={this.state.message} onChange={(e)=>this.setState({message:e.target.value})}></textarea>


                <div className="underline"></div>
            </div>

        </div>


            {this.state.success == false && this.state.error ? <h5 style={{ position:"absolute",top:"390px",color:"red" }}>Something went wrong, Please try again.</h5> :null}
        </div>
</Fade>
}
        

</div>


                <div className="contactpage-icon-container">

                    <div style={{ display:"flex",width:"100%",justifyContent:"center",marginTop:"100px" }}>

                            
             {        this.state.success && this.state.error == false ? null
                                    :               
                                    <button onClick={()=> (this.state.current >1 && this.setState({current:this.state.current-1}) ||
                                    (this.state.current ==1 && this.props.setShowForm(false))
                                    
                                    )} role="button" className="contactpage-btnleft" tabindex="-1">
                                                            <span><div >
                                                                <i >
                                                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path></svg>
                                                                        </i>
                                                                        </div>
                                                            </span>
                                                        </button>
                    
                                                        }
                                                        
                                             {       
                                                this.state.current < 3 && this.state.success == false ?
                                             
                                             <button onClick={()=>this.setState({current:this.state.current+1})} role="button" className="contactpage-btnright" tabindex="-1">
                                                            <span><div >
                                                                <i >
                                                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path></svg>
                                                                        </i>
                                                                        </div>
                                                            </span>
                                                        </button>

                                                        : this.state.success && this.state.error == false ? 


                                                        <button onClick={()=>this.props.setShowForm(false)} className="contactpage-btnright" tabindex="-1">
                                                        <span><div >
                                                           Back to Contact
                                                                    </div>
                                                        </span>
                                                    </button>


                                                        :

                                                        <div onClick={()=> this.submitForm()} role="button" className="contactpage-btnright" tabindex="-1">
                                                        <span><div >
                                                           submit
                                                                    </div>
                                                        </span>
                                                    </div>
                    
                    }
                    
                                    </div>
                    </div>


                </div>
                </Fade>


                
            </section>
        )
    }
}

export default Contact2;
