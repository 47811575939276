import React from 'react'
import Fade from 'react-reveal/Fade';

export default function ManifestSection2() {
  return (
    <React.Fragment>
    <section style={{backgroundColor:"#3881B6"}} className="seo_features_one sec_pad">
        <div className="container">
            <div className="row">
              
              

            <div className="col-lg-6 d-flex align-items-center">
                    <Fade bottom cascade>
                        <div className="seo_features_content">
                            <h6 style={{color:"#36f6e2"}} className='wow fadeInUp'>Manifest empowers you to define your financial goals, whether short-term or long-term, and create a customized investment plan to achieve them. The process is straightforward: you set your goals, specify the amount you wish to invest regularly (like monthly SIPs - Systematic Investment Plans), and let our platform do the rest.</h6>
                        </div>
                    </Fade>
                </div>


              
                <Fade left>
                <div className="col-lg-6 align-items-center">
                    <div className="">
                        <div className="round_circle"></div>
                        <div className="round_circle two"></div>

                        <div className="seo_features_content">
                            <h6 style={{color:"#ffffff"}} className='wow fadeInUp'>Our algorithms and professional expertise work together to create investment portfolios tailored to your objectives and risk tolerance. Whether it's saving for education, a down payment, or a comfortable retirement, Manifest ensures your investments are aligned with your dreams.</h6>
                        </div>

                   



                    </div>
                </div>
                </Fade>



                
             

                <Fade bottom cascade>

                <div style={{ marginLeft: "10px" }} className="seo_features_content">
                <h6 style={{color:"#fff"}} className='wow fadeInUp'>With Manifest, you gain control over your financial destiny, and the path to your goals becomes clearer than ever. Start planning today with YPay Manifest and embark on a journey toward financial success.</h6>
                
                </div>
                
                </Fade>

            </div>
        </div>
    </section>
</React.Fragment>
  )
}
