import React, {Component} from "react";
import Slider from 'react-slick';
import HostingMap from '../HostingMap';
import Sectitle from '../Title/Sectitle';
import { Fade } from "react-reveal";
import Reveal from 'react-reveal/Reveal';

class ErpTestimonial extends Component {
    constructor(){
        super();
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.state={
            items:[
                {
                    "id": 1,
                    "image": "testimonial_img.jpg",
                    "description": "I have been using YPay’s app for the last three months, and  I have to say it does make investments easy and accessible. I mean, you’ve got one platform for all your investment needs, plus they’ve got a “Mashwara” program- under which you can book a slot for all of your financial queries – the best part is that it is free!",
                    "name": "Fatima Khan",
                    "postion": "",
                    "location": "",
                    "stars":[1,2,3,4]
                },
                {
                    "id": 2,
                    "image": "testimonial_img2.jpg",
                    "description": "As a new customer of YPay, I have to say I love the experience. You can invest as low as 1000 rupees and redeem it at any time. Moreover, it has many excellent features, like the calculator and the many blogs. Super satisfied with the overall experience.",
                    "name": "Haris Manzoor",
                    "postion": "",
                    "location": "",
                    "stars":[1,2,3,4]

                },
                {
                    "id": 3,
                    "image": "testimonial_img2.jpg",
                    "description": "Overall, using the YPay app was super easy. I loved that everything was so simple and easy to follow. I only know a little about investment apps, but this experience was terrific. ",
                    "name": "Maheen Ismail",
                    "postion": "",
                    "location": "",
                    "stars":[1,2,3,4,5]

                },
                {
                    "id": 4,
                    "image": "testimonial_img2.jpg",
                    "description": "Providing a very easy investment solution.",
                    "name": "Huma Yaseen",
                    "postion": "",
                    "location": "",
                    "stars":[1,2,3,4,5]
                },
                {
                    "id": 5,
                    "image": "testimonial_img2.jpg",
                    "description": "This app is so good. Super helpful if you are a beginner in investing your money!! Download now and invest now.",
                    "name": "Ahmed Yaqoob",
                    "postion": "",
                    "location": "",
                    "stars":[1,2,3,4,5]
                }
            ]
        }

    }
    next() {
    this.slider.slickNext();
    }
    previous() {
    this.slider.slickPrev();
    }
    render(){
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 2000,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 700,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
        };
        return( 
            
        
            <section className="seo_features_one sec_pad">
                <div className="container">
                    <div className="row flex-row">
                        <Fade bottom cascade>
                        <div className="col-lg-6">
                           
                        {/* <div className="seo_features_img"> */}
                                {/* <div className="round_circle"></div> */}
                                {/* <div className="round_circle two"></div> */}
                                {/* <img style={{ maxWidth:"400px" }} src={require('../../img/seo/features_img.png')} alt=""/> */}
            <HostingMap/>

                            {/* </div> */}

                        </div>
                        </Fade>

                        <Fade bottom cascade>
                    <div className="col-lg-6">
                            {/* <Fade bottom cascade> */}
                               
            <section className="erp_testimonial_area ">
                <div className="container">


                <div className="hosting_title erp_title">
                <Reveal effect="fadeInUp" duration={1300}><h2 className={`f_p f_size_30 l_height50 f_600`}>Our valuable customers say about YPay</h2></Reveal>
                <Reveal effect="fadeInUp" duration={1600}><p style={{ textAlign:"start" }} className="f_400 f_size_16 mb-0">Hear it from the people! Our satisfied customers are raving about YPay - the ultimate financial education platform transforming the game in Pakistan.</p><p style={{ textAlign:"start" }} className="f_400 f_size_16 mb-0"> Join the revolution and become a part of the YPay community. Say goodbye to financial uncertainty and hello to a brighter financial future with YPay.</p></Reveal>
            </div>

                    <div className="row">
                        <div className="slider_nav">
                            <i className="arrow_left prev" onClick={this.previous}></i>
                            <i className="arrow_right next" onClick={this.next}></i>
                        </div>
                        <Slider ref={c => (this.slider = c)} className="erp_testimonial_info" {...settings}>
                            {this.state.items.map(item =>(
                                <div className="item" key={item.id}>
                                    <div className="erp_testimonial_item">
                                        <div className="content">
                                            <p>{item.description}</p>
                                            <div className="ratting">
                                                {item.stars.map((e,i)=>{
                                                    return <a key={i} href="/#"><i className="icon_star"></i></a>
                                                })}
                                                
                                            </div>
                                        </div>
                                        <div className="media">
                                            {/* <img src={require ("../../img/erp-home/" + item.image)} alt=""/> */}
                                            <div className="media-body">
                                                <h5>{item.name}<span>{item.postion}</span></h5>
                                                <p>{item.location}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </section>
                    </div>
                            </Fade>
                </div>
                </div>

            
            </section>
    



        )
    }
}

export default ErpTestimonial;