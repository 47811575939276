import React, {Component} from 'react';
import SecurityServiceItem from './SecuirtyServiceItem';

class SecurityService extends Component {
    render(){
        return(
            <section className=" sec_pad">
                <div className="container">

                    <SecurityServiceItem image="founder1.jpg" Title="Sarfraz’s Story"
                    description1="" description2="Sarfraz Hussain is the Chief Executive Officer (CEO) of YPay Financial Services Pvt. Ltd. He graduated from Habib University with a degree in Computer Science (CS). He became one of the few individuals who dived into the Fintech industry, introducing YPay as the most accessible investment app in Pakistan - your one stop for all your investment needs!"/>

                    <SecurityServiceItem image="founder2.jpg" rClass="flex-row-reverse" Title="Furqan’s Story"
                    description1=""
                    description2="Muhammad Furqan Karim is the co-founder and Chief Operating Officer YPay Financial Services. He has completed his undergraduate from Habib University, specializing in the field of IT. Furthermore, he did his Data Science Intensive Studies Certification from Stanford University, California US in 2018. "
                    />
                </div>
            </section>
        )
    }
}

export default SecurityService;