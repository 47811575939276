import React,{useEffect} from 'react';
import ServiceData from '../Service/ServiceData';

const Careers =(props)=>{

    // useEffect(() => {
    //     const script = document.createElement('script');
      
    //     script.src = "https://s3.amazonaws.com/files.freshteam.com/production/114165/attachments/5002049237/original/5000046597_widget.js?1644318096";
    //     script.async = true;
      
    //     document.body.appendChild(script);
      
    //     return () => {
    //       document.body.removeChild(script);
    //     }
    //   }, []);


      
    return(
        <section className="blog_area sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 blog_sidebar_left">
                        <div className="blog_single mb_50" style={{textAlign:"center"}}>
                           
                            {/* <div id='freshteam-widget'></div> */}

                            <p style={{fontSize:28}}>If you're interested in joining YPay, please feel free to contact</p>
                            <a style={{fontSize:20}} href='mailto:talent@ypayfinancial.com'>talent@ypayfinancial.com</a>


                        </div>
                      
                    </div>
                    {/* <div className="col-lg-4">
                    </div> */}
                </div>
            </div>
        </section>
    )
}
export default Careers;


// import React, { Component } from "react";
// import Axios from "axios";
// import c from "./SingleBlog.module.css";
// import Spinner from "../Spinner/Spinner";
// import Blogrightsidebar from './Blogrightsidebar';
// import ServiceData from '../Service/ServiceData';

// export class SingleBlog extends Component {
 
//   constructor(props) {
//     super(props);
//     this.state = {
//       singlePost: {},
//       allPost: [],
//       titleid: "",
//       avatar: "",
//       profileLink: "",
//       error:null,
//       isloading:true,
//       date:"",
//       year:{"01":"Jan",
//       "02":"Feb",
//       "03":"Mar",
//       "04":"Apr",
//       "05":"May",
//       "06":"Jun",
//       "07":"Jul",
//       "08":"Aug",
//       "09":"Sep",
//       "10":"Oct",
//       "11":"Nov",
//       "12":"Dec",}
//     };
//   }
//   mediumURL =
//     "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@ypayfinancialservices";

//   componentDidMount() {
//     window.scrollTo(0,0)
//     // console.log("window blog single",window.location)
    
//     this.setState({titleid: decodeURI(window.location.pathname)})


//     Axios.get(this.mediumURL)

//       .then((data) => {
//         let allPost = data.data.items;
//         allPost.splice(3,1)
//         this.setState({allPost: allPost});
//         // console.log("Blog single",data)
//         const avatar = data.data.feed.image;
//         const profileLink = data.data.feed.link;
//         const res = allPost; 
//         const posts = res.filter((item) => item.categories.length > 0);
//         for (let i in posts) {
//           const title = "/BlogSingle/" + posts[i].title;
//           // console.log("title",title)
//           // console.log("titleid",this.state.titleid)
          
//           if (title.replace(/\?/g,'') === this.state.titleid) {
//             // console.log("Working")
//             let post = posts[i];

//             let date = String(post.pubDate)
            
//               this.setState((p) => ({
//                 singlePost: post,
//                 avatar: avatar,
//                 profileLink: profileLink,
//                 isloading:false,
//                 date:date
//               }));
            
//           }
//         }
        
//       })
//       .catch((e) => {
//         this.setState({error:e.toJSON()})
//         console.log(e);
//       });
//   }

//   render() {

    
//     return (
//       // <div className={`container ${c.center}`}>
//       //  {post}
//       // </div>
//         <section className="blog_area sec_pad">
//             {this.state.singlePost ?   <div className="container">
//                 <div className="row">
//                     <div className="col-lg-8 blog_sidebar_left">
//                         <div className="blog_single mb_50">

//                             <div className="blog_content">
                               
                                
//                                     <h5 style={{fontWeight:"700",marginBottom:"50px"}} className="f_p f_size_30 f_500 t_color">{this.state.singlePost.title}</h5>


//                                 <div className="blog_single mb_50" dangerouslySetInnerHTML={{ __html:this.state.singlePost.description}}>
//           </div>

//                                 <div className="post_share">
//                                     <div className="post-nam"></div>
//                                     {/* <div className="flex">
//                                         <a href=".#"><i className="ti-facebook"></i>Facebook</a>
//                                         <a href=".#"><i className="ti-twitter"></i>Twitter</a>
//                                         <a href=".#"><i className="ti-pinterest"></i>Pinterest</a>
//                                     </div> */}
//                                 </div>
                               
//                                 <div className="media post_author mt_60">
//                                     <img className="rounded-circle" style={{width:"50px",height:"50px"}} src={this.state.avatar} alt=""/>
//                                     <div className="media-body">
//                                         <h5 className="f_p t_color3 f_size_18 f_500">{this.state.singlePost.author}</h5>
//                                         <h6 className="f_p f_size_15 f_400 mb_20">Editor</h6>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
                      
//                     </div>
//                     <div className="col-lg-4">
//                     {this.state.allPost.length > 0 ? <Blogrightsidebar blogsData={this.state.allPost}  heading="Recent Blogs" ServiceData={ServiceData.rpostblog}/> : <Spinner />}
//                     </div>
//                 </div>
//             </div> : this.state.error ? <div><h1>Something went wrong</h1></div> : <Spinner/> }
//         </section>
//     );
//   }
// }

// export default SingleBlog;
