import React from 'react'
import Fade from 'react-reveal/Fade';

export default function MoneyCircle1() {
  return (
    <React.Fragment>
    <section className="seo_features_one sec_pad">
        <div className="container">
            <div className="row">
              
              

            <div className="col-lg-7 d-flex align-items-center">
                    <Fade bottom cascade>
                        <div className="seo_features_content">
                            <h1 className="wow fadeInUp">Money Circles</h1>
                            <h6 className='wow fadeInUp'>Pool funds together with friends and family using YPay’s digital saving committees.</h6>
                        </div>
                    </Fade>
                </div>


              
                <Fade left>
                <div className="col-lg-5">
                    <div className="">
                        <div className="round_circle"></div>
                        <div className="round_circle two"></div>

                        {/* <CulturePicSlider className="about-page-cultuepic-mobileview"/> */}
                        <div style={{display:"flex"}}>
                        {/* <img style={{ maxWidth:"80%" }} src={require("../../img/moneycircle/moneycircle1.png")}/> */}
                        <img style={{ maxWidth:"100%" }} src={require("../../img/moneycircle/moneycircle.png")}/>
                        
                        </div>

                   



                    </div>
                </div>
                </Fade>



                
             

                {/* <Fade bottom cascade>

                <div className=' d-flex flex-column  seo_features_content about-page-culture-section-desktop-view'>
                <h2 className="wow fadeInUp">Values</h2>
                <h6 className='wow fadeInUp'>We make every decision and measure every outcome based on how well it serves our customers. We constantly push ourselves to be our best, we focus on solutions, and we arrive every day inspired to make an impact through our talents, passion and hard work.</h6>
                
                </div>
                
                </Fade> */}

            </div>
        </div>
    </section>
</React.Fragment>
  )
}
