import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import Breadcrumb from "../components/Breadcrumb";
// import Partner from '../components/Partner';
import FooterTwo from "../components/Footer/FooterTwo";
import FooterData from "../components/Footer/FooterData";
// import Features from '../components/Features/Features';
import CloudServiceTab from "../components/Service/Sservice/CloudServiceTab";
import SecurityService from "../components/Service/SecurityService";
// import EventFeatures from "../components/Features/EventFeatures";
import Skeleton from "react-loading-skeleton";

import EventTeam from "../components/Team/EventTeam";
import EventDetails from "../components/Features/EventDetails";
import GroupPicSlider from "../components/Slider/GroupPic/GroupPicSlider.js";
import CulturePic from "../components/Slider/CulturePic/CulturePic";
import BackedBy from "../components/BackedBy/BackedBy.js";

const About = () => {
  return (
    <div className="body_wrapper">
      <CustomNavbar
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu ml-auto mr-auto"
      />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area"
        imgName="breadcrumb/banner_bg.png"
        Ptitle="About Us"
        Pdescription="Home"
        Cdescription="> About Us"
      />
      {/* <EventFeatures /> */}

      <CulturePic />

      <GroupPicSlider />

      <SecurityService />

      {/* <Features aClass="agency_featured_area_two"/> */}

      <EventDetails />

      {/* <CloudServiceTab/> */}

      <EventTeam />

      <BackedBy />

      {/* <Partner pClass="partner_logo_area_five bg_color"/> */}
      <CloudServiceTab />

      <FooterTwo FooterData={FooterData} />
    </div>
  );
};
export default About;
