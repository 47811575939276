import React from 'react';
import SeoTitle from '../Title/SeoTitle';
import Fade from 'react-reveal/Fade';

const MashwaraComponent = () => {
    return(
        <React.Fragment>

            <section className="seo_features_one sec_pad">
                <div className="container">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-6">
                            <div className="seo_features_img">
                                {/* <div className="round_circle"></div> */}
                                {/* <div className="round_circle two"></div> */}
                                <img style={{ marginTop:"-100px" }} src={require('../../img/mashwara/main.png')} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <Fade bottom cascade>
                                <div className="seo_features_content">
                                    <h2>Mashwara</h2>
                                    <p style={{ fontSize:"18px" }}> YPay has launched its exclusive Financial awareness program, "Mashwara". This program is designed to provide personalized educational insights to individuals, equip them with sufficient financial knowledge, and prepare them to take control of their financial future!

                                    </p>
                                    <p style={{ fontSize:"18px" }}>Get personalized education and learn how to navigate through tough economic times wisely by scheduling a Mashwara session. Book a session Now at your preferred time slot!</p>
                                    <p style={{ fontSize:"18px" }}>Disclaimer: YPay only provides details and does not offer any financial advice or recommend a mutual fund. All returns advertised within our App are subject to market risk.</p>
{/* 
                                    <div className="media seo_features_item">
                                        <div className="media-body">
                                            <h3>Simplify your Investments</h3>
                                            <p>Invest in multiple funds in just one place.</p>
                                        </div>
                                    </div> */}

                        
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>

        </React.Fragment>
    )
}

export default MashwaraComponent;
