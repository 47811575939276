const FooterData = {
    CompanyWidget: [
        {
            id: 1,
            title: "Get In Touch",
            description: "Don’t miss any updates of our new templates and extensions.!",
        }
    ],
   
    termsCondition: [
      {
          id: 1,
          title: "Team Solutions",
          menuItems: [
            {
              id: 1,
              url: '#',
              text: 'Support',
            },
            {
              id: 2,
              url: '#',
              text: 'Sales',
            },
            {
              id: 3,
              url: '#',
              text: 'Report Abuse',
            },
            {
              id: 4,
              url: '#',
              text: 'System Status',
            }
        ],
      }
    ],
    SocialLinks: [
        {
            id: 1,
            title: "Team Solutions",
            menuItems: [
                {
                  id: 1,
                  url: '#',
                  icon: 'ti-facebook',
                },
                {
                  id: 2,
                  url: '#',
                  icon: 'ti-twitter-alt',
                },
                {
                  id: 3,
                  url: '#',
                  icon: 'ti-vimeo-alt',
                },
                {
                  id: 4,
                  url: '#',
                  icon: 'ti-pinterest',
                }
            ]
        }
    ],
    socialIcon:[
      {
        id: 1,
        url: 'https://www.facebook.com/ypaypk',
        icon: 'ti-facebook',
      },
      {
        id: 2,
        url: 'https://twitter.com/ypayfinancialpk',
        icon: 'ti-twitter-alt',
      },
      {
        id: 3,
        url: 'https://www.instagram.com/ypayfinancialservices/',
        icon: 'ti-instagram',
      },
      {
        id: 4,
        url: 'https://www.linkedin.com/company/ypayfinancial/',
        icon: 'ti-linkedin',
      }
    ],
    copywrite:"Copyright © 2022 All rights reserved",
  
    aboutLinks: [
      {
        id: 1,
        url: '/blogs',
        item: 'Our Blog',
      },
      {
        id: 2,
        url: '/about',
        item: 'Our Company',
      },
      {
        id: 3,
        url: '/team',
        item: 'Our Team',
      },
      {
        id: 4,
        url: '/careers',
        item: 'Join Us',
      }

    ],
    helpLinks: [
      {
        id: 1,
        url: '/faqs',
        item: 'FAQs',
      },
      {
        id: 2,
        url: '/contact',
        item: 'Help & Support',
      },
      {
        id: 3,
        url: '/privacypolicy',
        item: 'Privacy Policy',
      },
      {
        id: 4,
        url: '/mashwara',
        item: 'Mashwara',
      },
      {
        id: 5,
        url: '/resources/accountformat',
        item: 'Resources',
      }
      
    ],
    PrivacyLinks: [
      {
        id: 1,
        url: '/privacypolicy',
        item: 'Privacy Policy',
      },
    ],
    about: [
      {
        id: 1,
        url: '/blogs',
        text: 'Our Blog',
      },
      {
        id: 2,
        url: '/about',
        text: 'Our Company',
      },
      {
        id: 3,
        url: '/team',
        text: 'Our Team',
      },
      {
        id: 4,
        url: '/careers',
        text: 'Join Us',
      }
   
    ],
    Solution: [
      {
        id: 1,
        url: '/faqs',
        text: 'FAQs',
      },
      {
        id: 2,
        url: '/contact',
        text: 'Help & Support',
      },
      {
        id: 3,
        url: '/privacypolicy',
        text: 'Privacy Policy',
      },
      {
        id: 4,
        url: '/mashwara',
        text: 'Mashwara',
      },
      {
        id: 5,
        url: '/resources/accountformat',
        text: 'Resources',
      }
   
    ],
};
export default FooterData;