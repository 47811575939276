import React, {useState} from 'react';
import axios from "../axios";
import {Link} from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import Contact2 from './Contact2';
// import Image1 from "../img/Contact/1.png"
// import Image2 from "../img/Contact/2.png"
// import Image3 from "../img/Contact/3.png"
import Contactchat from "../img/Contact/contact-chat.jpg"
import Contactphone from "../img/Contact/contact-phone.jpg"
import Contactemail from "../img/Contact/contact-email.jpg"
// import SubmitButton from "../img/Contact/submit-button.png"


const Contacts =()=>{

        const [showForm, setShowForm] = useState(false)

        return(
            <section style={{ padding:"80px 0px" }} className="contactpage-main-section sec_pad">


             {   
             
             showForm ?
             <div>
                 <Contact2 setShowForm={setShowForm}/>
             </div>

             :
             
             <div className='contactpage-main-container'>
                <p style={{ fontFamily:"Poppins",marginBottom:"40px"}}>Need help? We're here for you. Our Customer Support Team is here to answer all your questions - from how to make an investment to resolving any and every issue that comes up in your journey with us!</p>

                    <div className='contactpage-card'>
                        
                        <img style={{ maxWidth:"100%" }} src={Contactchat}/>

                        {/* <div>
                        <h1 style={{  fontSize:"25px",   fontWeight: "700", fontFamily:"futura-pt-bold,system-ui,Helvetica Neue,sans-serif" }}>Chat with us</h1>
                        <p style={{  fontSize:"16px"}}>Got questions? We've got answers!</p>
                        <p style={{  fontSize:"16px"}}>&#x2022; Our Virtual Assistant 24/7 here for you, or</p>
                        <p style={{  fontSize:"16px"}}>&#x2022; Connect with our team during business hours</p>
                        <div className='contactpage-card-timings-container'>
                            <p><span style={{  fontSize:"16px",   fontWeight: "700", fontFamily:"futura-pt-bold,system-ui,Helvetica Neue,sans-serif" }}>Monday-Friday:</span> 10 am - 6 pm <span style={{  fontSize:"16px",   fontWeight: "700", fontFamily:"futura-pt-bold,system-ui,Helvetica Neue,sans-serif" }}>(PKT)</span></p>
                            <p style={{ color: "rgb(144 144 144",fontSize: "14px",marginTop: "2rem" }}>* Business hours may be subject to change on holidays</p>
                        </div>
                        </div> */}

                        {/* <div>
                        <img data-main-image="" className='contactpage-card-image' sizes="(min-width: 250px) 250px, 100vw" decoding="async" loading="lazy" alt="" src={Image3} srcset={`${Image3} 250w`}/>
                        </div> */}


                    </div>

                    <div className='contactpage-card'>
                        


                    <div >
                    <img style={{ maxWidth:"100%" ,maxHeight:"100%"}} src={Contactemail}/>
                    <button onClick={() => setShowForm(true)} className='contactpage-submitbutton'>Submit a request</button>
                    </div>

                        {/* <div>
                        <h1 style={{  fontSize:"25px",   fontWeight: "700", fontFamily:"futura-pt-bold,system-ui,Helvetica Neue,sans-serif" }}>Reach us by email</h1>
                        <p style={{  fontSize:"16px"}}>You can contact us at any time 24/7, at hello@ypayfinancial.com</p>
                        <button onClick={() => setShowForm(true)} className='contactpage-request-button'>Submit a request</button>
                        </div>
                        <div>
                        <img data-main-image="" className='contactpage-card-image' sizes="(min-width: 250px) 250px, 100vw" decoding="async" loading="lazy" alt="" src={Image2} srcset={`${Image2} 250w`}/>
                        </div> */}


                    </div>

                    <div className='contactpage-card'>

                    <img style={{ maxWidth:"100%" }} src={Contactphone}/>

                        
                        {/* <div>
                        <h1 style={{  fontSize:"25px",   fontWeight: "700", fontFamily:"futura-pt-bold,system-ui,Helvetica Neue,sans-serif" }}>Reach us by phone</h1>
                        <p style={{  fontSize:"16px"}}>You can reach our Customer Support Team by phone at +923152008963</p>
                        
                        <div className='contactpage-card-timings-container'>
                            <p><span style={{  fontSize:"16px",   fontWeight: "700", fontFamily:"futura-pt-bold,system-ui,Helvetica Neue,sans-serif" }}>Monday-Friday: </span> 10 am - 6 pm <span style={{  fontSize:"16px",   fontWeight: "700", fontFamily:"futura-pt-bold,system-ui,Helvetica Neue,sans-serif" }}>(PKT)</span></p>
                        </div>
                        </div>

                        <div>
                        <img data-main-image="" className='contactpage-card-image' sizes="(min-width: 250px) 250px, 100vw" decoding="async" loading="lazy" alt="" src={Image1} srcset={`${Image1} 250w`}/>
                        </div> */}


                    </div>



                </div>
                
                }

                
            </section>
        )
}

export default Contacts;
