import React, {Component} from 'react';
import Reveal from 'react-reveal/Reveal/';
import { Link } from 'react-router-dom';
class BreadcrumbCareers extends Component {
    render(){
        var {Ptitle, Pdescription,breadcrumbClass, Cdescription} = this.props;
        return(
            <section className={`${breadcrumbClass}`} style={{ padding:0 }}>
                <img className="" style={{ width:"100%",height:"100%" }} src={require ("../img/" + "careers/bg.jpg")} alt=""/>
            </section> 
        )
    }
}
export default BreadcrumbCareers;