import React from 'react';
import SeoTitle from '../Title/SeoTitle';
import Fade from 'react-reveal/Fade';
import VideoSlider from '../Slider/VideoSlider';

const Service = () => {
    return(
        <React.Fragment>
            <section className="seo_features_one sec_pad">
                <div className="container">
                    <div className="row">
                        <Fade left>
                        <div className="col-lg-7">
                            <div className="">
                                <div className="round_circle"></div>
                                <div className="round_circle two"></div>

                                <VideoSlider/>

                            </div>
                        </div>
                        </Fade>
                        <div className="col-lg-5 d-flex align-items-center">
                            <Fade bottom cascade>
                                <div className="seo_features_content">
                                    <h2 className="wow fadeInUp">Get to Know us Better! </h2>
                                    <h6 className="wow fadeInUp">YPay provides a platform for individuals to learn about investment opportunities and make informed decisions without relying on intermediaries.</h6>
                                    <h6 className="wow fadeInUp">The goal of YPay is to empower people with financial literacy and help them achieve their financial goals through smart investments.</h6>
                                    {/* <p className="wow fadeInUp">Cheeky bugger gosh codswallop chap bamboozled blatant cracking goal brown bread, pear shaped cor blimey guvnor easy peasy lemon squeezy hotpot spiffing good time, chancer a spend a penny spiffing I don't want no agro tinkety tonk old fruit.</p> */}
                                    {/* <a href=".#" className="seo_btn seo_btn_one btn_hover wow fadeInUp">Learn More</a> */}
                                    <a style={{ 
                                        background: "black",
                                        padding: "4px",
                                        color: "white",
                                        alignItems: "center",
                                        display: "inline-flex",
                                        minWidth: "231px",
                                        height: "48px",
                                        borderRadius: "24px",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                        textAlign: "center",
                                        fontFamily: "proxima-nova-semibold"
                                     }} href="https://www.youtube.com/@ypayfinancialservices207?view_as=subscriber?sub_confirmation=1" target="_blank" alt="Subscribe to Youtube" class="primaryBtn subscribe-btn"><img
                                     style={{ 
                                        marginRight: "14px",
                                        float: "left",
                                        width: "40px",
                                        borderRadius: "100%",
                                        maxWidth: "100%",
                                        verticalAlign: "middle"
                                      }}        
                                     src="https://img.smartspends.com/static/images/etmoneyweb/mf-explore/invest-with-us/subscribe-bell-icon.gif" class="" alt="Subscribe to Youtube"/> <span>Subscribe to <b>Youtube</b></span></a>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Service;
