import React from 'react'
import Fade from 'react-reveal/Fade';

export default function ProductSection2() {
    return (
        <React.Fragment>
            <section style={{ backgroundColor: "#3881B6" }} className="seo_features_one sec_pad">
                <div className="container">
                    <div className="row">



                        <div className="col-lg-6 d-flex align-items-center">
                            <Fade bottom cascade>
                                <div className="seo_features_content">
                                    <h6 style={{ color: "#36f6e2" }} className='wow fadeInUp'>Mutual funds are a popular and effective way to invest in financial markets, and they lie at the heart of YPay's investment offerings. Simply put, a mutual fund is a pool of funds collected from various investors with a common objective: to grow their money.</h6>
                                </div>
                            </Fade>
                        </div>



                        <Fade left>
                            <div className="col-lg-6">
                                <div className="">
                                    <div className="round_circle"></div>
                                    <div className="round_circle two"></div>

                                    <div className="seo_features_content">
                                        <h6 style={{ color: "#ffffff" }} className='wow fadeInUp'>These funds are managed by professional fund managers who strategically invest in a diverse portfolio of stocks, bonds, or other assets on behalf of investors. One of the key advantages of mutual funds is diversification.</h6>
                                    </div>





                                </div>
                            </div>
                        </Fade>





                        <Fade bottom cascade>

                            <div style={{ marginLeft: "10px" }} className="seo_features_content">
                                <h6 style={{ color: "#fff" }} className='wow fadeInUp'>When you invest in a mutual fund, you gain exposure to a wide range of assets, spreading your risk across different investments. This diversification helps mitigate the impact of individual asset fluctuations and can lead to more stable and potentially rewarding returns over time.</h6>

                            </div>

                        </Fade>

                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}
