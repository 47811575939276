import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import BreadcrumbCareers from '../components/BreadcrumbCareers';
import Career from '../components/Careers/Careers'
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';

const Careers = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="careers_page_hide_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <BreadcrumbCareers breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Careers" Pdescription="Home" Cdescription="> Careers"/>
            <Career/>
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default Careers;