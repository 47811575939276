import React, {Component} from 'react';
import RewardsItem2 from './RewardsItem2';
import Slider from 'react-slick';

class RewardsSlider2 extends Component{
    render(){
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            arrows: true,
            // autoplay: true,
            autoplaySpeed: 2000,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  }
                }
              ],
        };
        return(
            <section className=" ">
            <div className="">
                <Slider ref={c => (this.slider = c)} className="rewardsslider2" {...settings}>
                    <RewardsItem2 image="culture1.jpg" name="John Deo" post="Wp Developer"/>
                    <RewardsItem2 image="culture2.jpg" name="Jason Response" post="Graphic Design"/>
                    <RewardsItem2 image="culture3.jpg" name="John Deo" post="Wp Developer"/>
                    <RewardsItem2 image="culture1.jpg" name="John Deo" post="Wp Developer"/>
                    <RewardsItem2 image="culture2.jpg" name="John Deo" post="Wp Developer"/>
                </Slider>
            </div>
        </section>
        )
    }
}

export default RewardsSlider2;