import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import Sticky from 'react-stickynode';

class CustomNavbar extends Component {


//     getStarted = () => {
//         // console.log(window)
//         window.location.replace("/#get_started_section")
//     }

//     componentDidMount(){
//         if(window.location.hash) {
//             var elmnt = document.getElementById("get_started_section");
//             elmnt.scrollIntoView();
//           } else {
//             // Fragment doesn't exist
//           }
// }


    render() {
        var {mClass, nClass, cClass, slogo, hbtnClass} =this.props;
        return (
            <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
                <header className="header_area">
                <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
                    <div className={`container ${cClass}`}>
                        <Link className={`navbar-brand ${slogo}`} to="/">
                            <img src={require("../img/2.png")} alt=""/>
                            <img src={require("../img/1.png")} alt="logo"/>
                        </Link>
                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="menu_toggle">
                                <span className="hamburger">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                                <span className="hamburger-cross">
                                    <span></span>
                                    <span></span>
                                </span>
                            </span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                                <li className="nav-item dropdown submenu mega_menu mega_menu_two">
                                <NavLink exact title="Home" className="nav-link" to='/'>Home</NavLink>
                                    
                                </li>
                                
                                <li className="dropdown submenu nav-item">
                                <NavLink exact title="About" className="nav-link" to='/about'>About</NavLink>
                                </li>
                            
                    

                                <li className="dropdown submenu nav-item">
                                    <Link to="./" title="Learn More" className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Learn</Link>
                                    <ul role="menu" className=" dropdown-menu">
                                        {/* <li className="nav-item"><NavLink exact title="News" className="nav-link" to='/newsletter'>NewsLetter</NavLink></li> */}
                                        {/* <li className="nav-item"><NavLink exact title="News" className="nav-link" to='/YPay101'>YPay101</NavLink></li> */}
                                        <li className="nav-item"><NavLink exact title="Blog" className="nav-link" to='/blogs'>Blogs</NavLink></li>
                                        {/* <li className="nav-item"><NavLink exact title="Tax Benefit" className="nav-link" to='/taxbenefit'>Tax Benefit</NavLink></li> */}
                                    </ul>
                                </li>


                                {/* <li className="nav-item"><NavLink title="Calculators" className="nav-link" to="/calculators">Calculators</NavLink></li> */}

                                <li className="nav-item"><NavLink title="Contact" className="nav-link" to="/contact">Contact</NavLink></li>

                                
                                
                                <li className="nav-item"><NavLink title="Mashwara" className="nav-link" to="/mashwara">Mashwara</NavLink></li>

                                {/* <li className="dropdown submenu nav-item">
                                <NavLink title="Hiring" className="nav-link" to="/careers">We're Hiring 🚀</NavLink>
                                </li> */}

 <li className="dropdown submenu nav-item">
                                    <Link to="./" title="Learn More" className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">YPay Product</Link>
                                    <ul role="menu" className=" dropdown-menu"> 
                                        {/* <li className="nav-item"><NavLink exact title="News" className="nav-link" to='/newsletter'>NewsLetter</NavLink></li> */}
                                        {/* <li className="nav-item"><NavLink exact title="News" className="nav-link" to='/YPay101'>YPay101</NavLink></li> */}
                                       <li className="nav-item"><NavLink exact title="YPay Product" className="nav-link" to='/product'>Main Offering</NavLink></li>
                                        <li className="nav-item"><NavLink exact title="YPay Manifest" className="nav-link" to='/manifest'>YPay Manifest</NavLink></li>
                                        <li className="nav-item"><NavLink exact title="YPay Money Circle" className="nav-link" to='/moneycircle'>YPay Money Circle</NavLink></li>
                                    </ul>
                                </li> 


                            </ul>
                            {/* <button className={`btn_get btn_hover ${hbtnClass}`} onClick={() => this.getStarted()} >We're Hiring 🚀</button> */}
                            <NavLink exact title="Hiring" to='/careers' className={`nav-link btn_get btn_hover ${hbtnClass}`}  >We're Hiring <span>🚀</span></NavLink>
                        </div>
                    </div>
                </nav>
                </header>
            </Sticky>
        );
    }
}

export default CustomNavbar;