import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import Taxbenefit from '../components/TaxBenefit/TaxBenefit.js'
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';

const TaxBenefit = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Tax Update" Pdescription="Home" Cdescription="> Tax Update"/>
            <Taxbenefit/>
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default TaxBenefit;