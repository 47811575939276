import React from 'react'
import Fade from 'react-reveal/Fade';

export default function MoneyCircle2() {
  return (
    <React.Fragment>
    <section style={{backgroundColor:"#3881B6"}} className="seo_features_one sec_pad">
        <div className="container">
            <div className="row">
              
              

            <div className="col-lg-6 d-flex align-items-center">
                    <Fade bottom cascade>
                        <div className="seo_features_content">
                            <h6 style={{color:"#36f6e2"}} className='wow fadeInUp'>Now Shumaila aunty can orgainze her committees from anywhere, with the reliability of Money Circles </h6>
                        </div>
                    </Fade>
                </div>


              
                <Fade left>
                <div className="col-lg-6">
                    <div className="">
                        <div className="round_circle"></div>
                        <div className="round_circle two"></div>

                        <div className="seo_features_content">
                            <h6 style={{color:"#ffffff"}} className='wow fadeInUp'>Get monthly reminders on payments. No more chasing after with reminders!
                            Choose your fine on late payments
                            Randomly assign a winner each month or have a lucky draw</h6>
                        </div>

                   



                    </div>
                </div>
                </Fade>



                
             

                <Fade bottom cascade>

                <div style={{ marginLeft: "10px" }} className="seo_features_content">
                <h6 style={{color:"#fff"}} className='wow fadeInUp'>Organizing committees has never been easier!</h6>
                
                </div>
                
                </Fade>

            </div>
        </div>
    </section>
</React.Fragment>
  )
}
