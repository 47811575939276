import React from 'react'
import Fade from 'react-reveal/Fade';

export default function ProductSection3() {
  return (
    <React.Fragment>
    <section className="seo_features_one sec_pad">
        <div className="container">
            <div style={{justifyContent:"center"}} className="row">
              
            <h2 style={{marginTop:"10px",marginBottom:"20px"}} className="wow fadeInUp">How to invest in Mutual Funds?</h2>
              

            <div className="d-flex align-items-center">
                    <Fade bottom cascade>
                            
                        <div style={{display:"flex",flexWrap:"wrap",width:"100vw"}} className="seo_features_content">

<img style={{width:"100%",height:"100%"}}  src={require("../../img/product/invest.jpg")}/>                       

</div>

                        {/* <img style={{ maxWidth:"25%" }} src={require("../../img/product/mutualfund1.png")}/>
                        <img style={{ maxWidth:"25%" }} src={require("../../img/product/mutualfund2.png")}/>
                        <img style={{ maxWidth:"25%" }} src={require("../../img/product/mutualfund3.png")}/>
                        <img style={{ maxWidth:"25%" }} src={require("../../img/product/mutualfund4.png")}/>
                        <img style={{ maxWidth:"25%" }} src={require("../../img/product/mutualfund5.png")}/>
                        <img style={{ maxWidth:"25%" }} src={require("../../img/product/mutualfund6.png")}/> */}


                    </Fade>
                </div>


                <h3 style={{marginTop:"20px",marginBottom:"20px",textAlign:"center"}} className="wow fadeInUp">To know more about Mutual Funds <a href='/faqs?mutualfunds' ><span>Click Here</span> </a></h3>
              

             
            </div>
        </div>
    </section>
</React.Fragment>
  )
}
