import React from 'react';
// import Blogrightsidebar from './Blogrightsidebar';
import BlogGridItem from './BlogGridItem';
import { Fade } from 'react-reveal';
import data from './BlogData';

const BlogGrid =()=>{
    return(
        <section className="blog_area_two sec_pad">
            <div className="container">
                 
                 <Fade bottom cascade>
                    <div>

                    <p>Welcome to our blog page, which aims to provide valuable insights and information on personal finance and investment. Whether you're a beginner looking to learn the basics or an experienced investor looking to stay up-to-date on the latest trends, our goal is to empower you with the knowledge you need to make informed investment decisions.</p>
                    <p>Our blog covers various topics, including investment trends, vehicles, personal finance, and more. We also share tips and strategies for building and managing a successful investment portfolio.</p>
                    <p>We hope you find our blog informative and helpful, and we welcome your feedback and suggestions. Thank you for joining us on this journey to become more financially updated.
</p>
</div>

</Fade>
                <div className="row">
                    <div className="col-lg-12 blog_grid_info">

                        <div className="row">
{   data && data.map((e,i) => {
    return   <BlogGridItem index={e.id} key={i} date={e.date} month={e.month} image={e.image} Title={e.Title} description={e.description}
    btn={e.btn} comment={e.comment} 
    content ={e.content}
    />
})
                      
}

                       

                        </div>
                        {/* <ul className="list-unstyled page-numbers shop_page_number text-left mt_30">
                            <li><span aria-current="page" className="page-numbers current">1</span></li>
                            <li><a className="page-numbers" href=".#">2</a></li>
                            <li><a className="next page-numbers" href=".#"><i className="ti-arrow-right"></i></a></li>
                        </ul> */}
                    </div>
                    {/* <div className="col-lg-4"> */}
                        {/* <Blogrightsidebar ServiceData={ServiceData}/> */}
                    {/* </div> */}
                </div>
            </div>
        </section>
    )
}
export default BlogGrid;