import React from 'react'
import Fade from 'react-reveal/Fade';

export default function MoneyCircle4() {
  return (
    <React.Fragment>
    <section style={{backgroundColor:"#3881B6",marginTop:"20px",marginBottom:"250px",padding:20}} className="seo_features_one">
        <div className="container">
            <div className="row">
              
              

            <div className="col-lg-6 d-flex align-items-center">
                    <Fade bottom cascade>
                        <div className="seo_features_content">
                            <h6 style={{color:"#36f6e2"}} className='wow fadeInUp'>Meet Cashew 🙌</h6>
                            <h6 style={{color:"#fff"}} className='wow fadeInUp'>Cashew  hangs out with us at YPay. Always ready to guide our customers, helping them learn and earn and grow their wealth with the easiest investment app in Pakistan.</h6>
                        </div>
                    </Fade>
                </div>


              
                <Fade left>
                <div className="col-lg-6">
                    <div className="">
                        <div className="round_circle"></div>
                        <div className="round_circle two"></div>

                        <div className="seo_features_content">
                             {/* <CulturePicSlider className="about-page-cultuepic-mobileview"/> */}
                        <div className='moneycircle-cashew-mobile' >
                        <img style={{ maxWidth:"60%" }} src={require("../../img/moneycircle/moneycircle4.png")}/>
                        </div>

                        </div>

                   



                    </div>
                </div>
                </Fade>



                
             

                {/* <Fade bottom cascade>

                <div style={{marginLeft:"20px"}}>
                <h6 style={{color:"#fff"}} className='wow fadeInUp'>Cashew  hangs out with us at YPay. Always ready to guide our customers, helping them learn and earn and grow their wealth with the easiest investment app in Pakistan.</h6>
                
                </div>
                
                </Fade> */}

            </div>
        </div>
    </section>
</React.Fragment>
  )
}
