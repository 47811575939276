import React from 'react';

const TaxBenefit =()=>{
    return(

        <section className="blog_area sec_pad">
       <div className="container">
            <div className="row">
                <div className="col-lg-8 blog_sidebar_left">
                    <div className="blog_single mb_50">

                        <div className="blog_content">
                           
                            
                                <h5 style={{fontWeight:"700",marginBottom:"50px"}} className="f_p f_size_30 f_500 t_color">Tax Update on Mutual Funds</h5>

                                <div className="blog_single mb_50">
                            <img  style={{ maxWidth:"600px" }} src={require("../../img/blog-grid/tax-benefit.png")} alt=""/>
                               
                     

                                    <h3 style={{ marginTop:"30px",marginBottom:"30px" }}>Tax Update - FY'22-23</h3>

                               
                               <div style={{ textAlign:"justify" }}>
                                <blockquote className="blockquote mb_40">
                                    <h6 className="mb-0 f_size_18 l_height30 f_p f_400">Tax Credit on Mutual Funds has been abolished but remains applicable on Voluntary Pension Schemes (VPS).</h6>
                                </blockquote>

                                <blockquote className="blockquote mb_40">
                                    <h6 className="mb-0 f_size_18 l_height30 f_p f_400">Income From Government Securities is now considered in Profit-on-Debt and will be taxed at the applicable rate.</h6>
                                </blockquote>

                                <blockquote className="blockquote mb_40">
                                    <h6 className="mb-0 f_size_18 l_height30 f_p f_400">Exemption tenure of Capital Gain Tax has been stretched to 6 years while Capital Gains Tax Rate(s) stay the same for Mutual Funds.</h6>
                                </blockquote>
                                </div>
                               
                                <div className="post_share">
                                    <div className="post-nam"></div>
                                </div>
                      
                                <div className="media post_author mt_60">
                                    <img className="rounded-circle"  style={{width:"50px",height:"50px"}} src={"https://cdn-images-1.medium.com/fit/c/150/150/1*Lpf_r5hdM79nsVnmADJTbw.png"} alt=""/>
                                    <div className="media-body">
                                        <h5 className="f_p t_color3 f_size_18 f_500">YPay Financial Services</h5>
                                        <h6 className="f_p f_size_15 f_400 mb_20">Editor</h6>
                                    </div>
                                </div>
                            </div>
                            </div>
      </div>

                          
                            
                </div>
                <div className="col-lg-4">
                </div>
            </div>
        </div>
    </section>

    )
}
export default TaxBenefit;