import React from 'react'
import Fade from 'react-reveal/Fade';

export default function MoneyCircle3() {
  return (
    <React.Fragment>

<section className="seo_features_one sec_pad">
        <div className="container">
            <div style={{justifyContent:"center"}} className="row">
              
            <h2 style={{marginTop:"10px",marginBottom:"20px"}} className="wow fadeInUp">How it Works</h2>
              

            <div className="d-flex align-items-center">
                    <Fade bottom cascade>
                        <div style={{display:"flex",flexWrap:"wrap",width:"100vw"}} className="seo_features_content">

                        <img style={{width:"100%",height:"100%"}}  src={require("../../img/moneycircle/work2.jpg")}/>                       

                        </div>
                    </Fade>
                </div>
            </div>
        </div>
    </section>
</React.Fragment>
  )
}
