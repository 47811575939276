import React from 'react';
import Newsrightsidebar from './Newsrightsidebar';
import Bloglistitem from './Bloglistitem';
import ServiceData from '../Service/ServiceData';
const Bloglists =()=>{
    return(
        <section className="blog_area sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <Bloglistitem ServiceData={ServiceData}/>
                        
                    </div>
                    <div className="col-lg-4">
                        <Newsrightsidebar showCategories={false} heading="Recent News" ServiceData={ServiceData.rpostnews}/>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Bloglists;