import React, { Component } from 'react';
import Chart from 'react-apexcharts'

class DonutChart extends Component {

  constructor(props) {
    super(props);

    this.state = {
      options: {
        labels: ["Invested Amount %","Estimated Return %"],
        dataLabels: {
          dropShadow: {
            blur: 3,
            opacity: 0.8
          }
        },
      },
    }
  }
  
  render() {
    
      // console.log("chart",this.props.estReturn)

    return (
      <div className="donut">
        <Chart options={this.state.options}  series={[this.props.estReturn,100-this.props.estReturn]} type="donut" style={{ maxWidth:"480px" }} />
      </div>
    );
  }
}

export default DonutChart;